import React, { useEffect } from 'react'

import { StyledModal, StyledModalDialog, StyledModalContent, StyledCloseOutside, StyledModalClose, StyledExpandedIcon } from 'theme'
import { RiCloseFill } from 'react-icons/ri'
import { useToast, hideToast } from 'hook'
import Icon from 'assets/icons/demo'
import { TextCom } from '../typo'

type ToastComProps = {
  children: any,
  isModal: boolean,
  handleIsModal: any,
  isSetTimeOut: number,
  isCloseBtn: boolean
  modalWidth: number
}
export const ToastCom: React.FC<ToastComProps> = ({ children, isModal, handleIsModal, isSetTimeOut, isCloseBtn = false, ...props }) => {
  const { createToast_data } = useToast()

  useEffect(() => {
    if (!createToast_data?.alway && createToast_data?.toastModalVisible) {
      setTimeout(() => {
        hideToast()
      }, createToast_data?.timer || 5000)
    }
  }, [createToast_data?.toastModalVisible])

  const handleExpanedIcon = () => {
    return (
      createToast_data?.type === 'success' ?
        <img src={Icon.SuccessSvg} />
        :
        createToast_data?.type === 'warning' ?
          <img src={Icon.WarningSvg} />
          :
          createToast_data?.type === 'confirm' ?
            <img src={Icon.ConfirmSvg} />
            :
            createToast_data?.type === 'error' &&
            <img src={Icon.ErrorSvg} />
    )
  }

  return (
    <StyledModal className={`fade ${createToast_data?.toastModalVisible ? 'show' : 'hide'}`} {...props}>
      <StyledCloseOutside onClick={hideToast} />
      <StyledModalDialog {...props}>
        {isCloseBtn &&
          <StyledModalClose onClick={hideToast}>
            <RiCloseFill />
          </StyledModalClose>
        }
        {
          createToast_data?.render ?
            createToast_data?.render
            :
            <StyledModalContent className='toast-content' style={{minWidth: 350}} {...props}>
              <StyledExpandedIcon>
                {handleExpanedIcon()}
              </StyledExpandedIcon>
              {createToast_data?.title && <TextCom size='sm' weight='lg' {...createToast_data?.titleStyle}>{createToast_data?.title}</TextCom>}
              {createToast_data?.desc && <TextCom size='xs' {...createToast_data?.descStyle}>{createToast_data?.desc}</TextCom>}
              {(!createToast_data?.title && !createToast_data?.desc) && <TextCom size='xs'>Required Text Field</TextCom>}
            </StyledModalContent>
        }
      </StyledModalDialog>
    </StyledModal>
  )
}