import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import serviceController, { routes, routeFilter } from '../../../controller'
import { isPendingAction, isFulfilledAction, isRejectedAction } from '../typehandle.action'
import Icon from "assets/icons/demo";
const initialState = {
  isLoading: false,
  error: null,
  moreModalVisible: false,
  flashVisible: true,
  langStore: {
    lang: 'မြန်မာ',
    code: 'my',
    lan_icon: Icon.myImage
  }
}

const getDictionary = createAsyncThunk('otherSlice/dictionary', async () => {
  return await serviceController(routes.getTranslation)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getMetaData = createAsyncThunk('otherSlice/getMetaData', async () => {
  return await serviceController(routes.getMetaData)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getPageCode = createAsyncThunk('otherSlice/getPageCode', async (data) => {
  const { code, ...field } = data
  return await serviceController(`${routes.getPageCode}/${code}${routeFilter(field)}`)
    .then(res => {
      if (res?.data) {
        return res.data[0]
      } else {
        return res.data[0]
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getWebsiteSlider = createAsyncThunk('otherSlice/getWebsiteSlider', async (data, thunkAPI) => {
  let { code, langCode, ...field } = data

  return await serviceController(`${routes.getWebsiteSlider}${code}?lang=${langCode}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getWebsiteBanner = createAsyncThunk('otherSlice/getWebsiteBanner', async langCode => {
  return await serviceController(`${routes.getWebsiteBanner}?lang=${langCode}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getContactUsPage = createAsyncThunk('otherSlice/getContactUsPage', async data => {
  return await serviceController(`${routes.getContactUsPage}${routeFilter(data)}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const sentContactUs = createAsyncThunk('otherSlice/sentContactUs', async data => {
  return await serviceController(routes.sentContactUs, data)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const otherSlice = createSlice({
  name: 'otherSlice',
  initialState,
  reducers: {
    setLangStore: (state, action) => ({
      ...state,
      langStore: action.payload
    }),
    setFlashVisible: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setEmit: (state, action) => ({
      ...state,
      emit_data: {
        [action.payload.key]: action.payload.data
      }
    })
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction('otherSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return ({
          ...state,
          isLoading: true,
          [tmp[1] + '_isLoading']: true,
          error: null
        })
      })
      .addMatcher(isFulfilledAction('otherSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return ({
          ...state,
          [tmp[1] + '_data']: action.payload,
          isLoading: false,
          [tmp[1] + '_isLoading']: false,
          error: null
        })
      })
      .addMatcher(isRejectedAction('otherSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return ({
          ...state,
          isLoading: false,
          [tmp[1] + '_isLoading']: false,
          error: action.payload // or you could use `rejectWithValue` and pull it from the payload.
        })
      })
  }
})

export default {
  otherSlice: otherSlice.reducer,
  setLangStore: otherSlice.actions.setLangStore,
  setEmit: otherSlice.actions.setEmit,
  setFlashVisible: otherSlice.actions.setFlashVisible,
  getDictionary,
  getMetaData,
  getPageCode,
  getWebsiteSlider,
  getWebsiteBanner,
  getContactUsPage,
  sentContactUs,
}