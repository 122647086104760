import { useState } from "react";
import { useNavigate } from "react-router-dom"

import { useCart } from "../../../hook";
import { FiSearch } from 'react-icons/fi'
import { FormContainerCom, InputCom } from "components";
import { SearchContainer } from "../../../theme";


const SearchCom = props => {
  const { getCart_data, CartAction, dispatch } = useCart()
  let [productName, setProductName] = useState('')
  const navigate = useNavigate()
  let [search_active, setSearch_active] = useState(false);

  const handleSearch = (e) => {
    productName !== '' && navigate(`/search/${encodeURI(productName)}`)
    setSearch_active(!search_active)
    setProductName('')
  }

  return (
    <SearchContainer className={`search-con ${search_active === true ? 'active' : ''}`}>
      <div className="search-input">
        <InputCom
          isValidate={false}
          placeholder='Search product'
          type='text'
          value={productName}
          onChange={e => setProductName(e.target.value)} onKeyPress={e => e.key === 'Enter' && handleSearch(e)}
        />
      </div>
      <FiSearch size={25} onClick={(e) => handleSearch(e)} />
    </SearchContainer>
  )
}

export default SearchCom