import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { CustomPlaceholder } from 'components'
import { cardVariable,generalGap,fontSize, fontWeight, InputGeneral , colors,media } from '../../attribute'
import { Text } from '../typo'
const Card = styled.div`
  box-sizing: border-box;
  box-shadow: 1px 1px 10px ${rgba(colors.gray, 0.15)};
  ${({ radius }) => radius && css`
    border-radius: 5px;
  `}
`
const CardContent = styled(Text)`
  padding: 15px;
`
const CardImg = styled.img`
  width: 100%;
  display: block;
  ${({ top }) => top && css`
    margin-bottom: 15px;
  `}
  ${({ bottom }) => bottom && css`
    margin-top: 15px;
  `}
  ${({ left }) => left && css`
    margin-right: 15px;
  `}
  ${({ right }) => right && css`
    margin-left: 15px;
  `}
`

const CardDiv = styled.div`
  position:relative;
  a {
    display: block;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
  .card-link{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    z-index:33;

  }
  .card-wrap{
      height: 100%;
      }
  .card {
    position: relative;
    /* border: ${InputGeneral.border} ${colors.light}; */
    border-radius: 15px;
    margin-bottom: 15px;

    img {
      &.opacity0 {
        width: 100%;
        opacity: 0;
      }
    }
  }

  ${({ border }) => border && css`
    .card-wrap {
      border: ${InputGeneral.border} ${colors.gray2};
      border-radius:  ${props => generalGap[props.radius] || props.radius|| '10'}px ;
      overflow: hidden;
      cursor: pointer;
      box-shadow: ${rgba(colors.gray, .03)} 0px 2px 5px 0px, ${rgba(colors.gray, .01)} 0px 2px 10px 0px;
      transition: box-shadow .3s ease;
      &:hover {
        box-shadow: ${rgba(colors.gray, .03)} 0px 2px 5px 0px, ${rgba(colors.gray, .04)} 0px 2px 10px 0px;
      }

      &:focus {
        outline: 0;
      }

      .card {
        border-radius: 0;
        border: 0;
      }
    }
    .content {
      text-align: center;
      /* border-top: ${InputGeneral.border} ${colors.light}; */
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;

      p {
        line-height: 20px;
        height: 40px;
        margin-bottom: 10px;
        &:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; 
          -webkit-box-orient: vertical;
        }
        &:last-child {
          min-height: 40px;
          margin-bottom: 0;
          span{
            &:first-child{
              font-size: ${fontSize.lg - 2}px;
              margin-right: 10px;
              font-weight: ${fontWeight.cu_w};
              @media(min-width: 425) and (max-width:991px){
              display: block;
            }
            }
          }
          del{ 
            color: ${colors.desc};
          }
          .percent{
            font-size: ${fontSize.sm}px;
            font-weight: ${fontWeight.lg};
            color: ${colors.primary};
          }
        }
      }
    }
    .card-btn {
      text-align: center;
      margin-bottom: 15px;
      width: 100%;
      &.promo-footer{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin: 0;
        padding: 10px 0;
        border-top: 1px solid ${colors.bgColor};
        .flex {
          display: flex;
          justify-content: space-around;
        }
      }
    }
    del {
      font-size: ${fontSize.sm}px;
      color: ${colors.inputColor};
      margin-right: 5px;
    }
  `}

  ${({ blog }) => blog && css`
    /* box-shadow: 0 2px 5px 0 ${rgba(colors.gray, .03)}, 0 2px 10px 0 ${rgba(colors.gray, .02)}; */
    .card-wrap {
      /* margin-bottom: 30px; */
      .content {
        > {
          &:first-child {
            font-size:${fontSize.lg - 2}px;
            margin-bottom: 10px;
          }
          &:last-child {
            line-height: normal;
          }
        }
      }
      .card-btn {
        button {
          width: 100%;
          margin: 0;
          svg {
            margin: 0;
          }
          &:hover {
            svg {
              color: ${colors.primary};
            }
          }
        }
      }
    }
  `}
  
  &.out_of_stock {
    .card-wrap {
      position: relative;
      &:before {
        content: '${(props => props?.pre_order_ui_text)}';
        width: 100%;
        height: 40px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffbc00;
        color: ${colors.white};
        font-weight: 700;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${rgba(colors.gray, .65)};
      }
    }
  }
`

const ProductCardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  .card-container{
    .card-link{
      height: 100%;
    }
    .card-wrap{
      height: 100%;
      .card-description{
          display: none;
        }
      .content{
        padding-inline: ${generalGap.xxl}px;
        padding-top: ${generalGap.lg}px;
        .product-name{
          word-wrap: break-word;
          color: ${colors.desc};
        }
      }
    }
  }
`

const ProductCol = styled.div`
  width: 100%;
  margin-bottom: 17px;

  ${props => props?.custom_layout === 'search_cart' ?
    `
    @media screen and (min-width: ${media.md}px) {
      width: 32%;
      margin-right: 14px;

      &:nth-child(2n + 2) {
        margin-right: 14px;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    @media screen and (min-width: ${media.lg}px) {
      width: 32%;
      margin-right: 19px;

      &:nth-child(2n + 2) {
        margin-right: 19px;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    @media screen and (min-width: ${media.xl}px) {
      width: 24%;
      margin-right: 14px;

      &:nth-child(2n + 2) {
        margin-right: 14px;
      }

      &:nth-child(3n + 3) {
        margin-right: 14px;
      }

      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }`
    :
    `
    @media screen and (min-width: ${media.md}px) {
      width: 48%;
      margin-right: 13px;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media screen and (min-width: ${media.lg}px) {
      width: 49%;
      margin-right: 13px;
    }

    @media screen and (min-width: ${media.xl}px) {
      width: 32%;
      margin-right: 16px;

      &:nth-child(2n + 2) {
        margin-right: 16px;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }`
  }

  &:focus {
    outline: 0;
  }
`

const Division = styled.div`
.card-content{
  margin-bottom: 30px;
  position: relative;

  h5 {
    color: ${colors.secondary};
    border: 0;
    padding: 0;
    margin: 0;
  }

  button {
    min-width: 100px;
    min-height: 30px;
    margin: 0;
    margin-right: 15px;
   
  }
  
  .card-title{
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(46,49,146);
    padding: 15px;
    background: linear-gradient(90deg, rgba(46,49,146,1) 0%, rgba(46,49,146,0.9) 25%, rgba(30, 145, 214, 0.9) 100%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    h5{
      color: ${colors.light}
    }
  }

  .division-card-content{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }

  .btn-group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 85px;
    z-index: 1;
  }
  transition: background .3s ease;

  &:hover {
    &:before{
      content: ' ';
      position:absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 15px;
    overflow: hidden;
      background-color: ${rgba(0, 0, 0, .1)};
    }
  }
}

`

const BackgroundData = styled.div`
  margin: 0;
  background: url("${props => props.data && props.data}") no-repeat center / ${props => props.resizeMode || 'cover'};
  ${({ border_radius }) => border_radius && css`
    border-radius: ${border_radius}px;
  `}

  img {
    width: 100%;
    opacity: 0;
  }
  margin-bottom: 10px;
`

const defaultSettings = {
  width: 360,
  height: 210,
  backgroundColor: colors.light,
  text: '360 x 220',
  opacity: 0
}

const BackgroundImage = ({ data, border_radius, ...props }) => {

  let defa = props?.listPromo ? { ...defaultSettings, width: 160, height: 90 } : { ...defaultSettings }

  return (
    <BackgroundData data={data} border_radius={border_radius} title={props?.title} {...props}>
      <CustomPlaceholder {...defa} />
      {props.children}
    </BackgroundData>
  )
}

const CardDate = styled.div`
  position: absolute;
  right: 0;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${rgba(colors.gray, .8)};
  color: ${colors.light};
  padding: 5px;
  @media screen and (min-width: ${media.md}px) {
    right: -5px;
  }
  span {
    font-size: ${fontSize.md - 2}px;
  }
  svg {
    width: 17px;
    height: 17px;
    color: ${colors.white};
    margin-right: 5px;
    &:hover {
      color: ${colors.white}
    }
  }
`

const Career = styled.div`
  background-color: ${colors.bgColor};
  box-shadow: 0 2px 5px 0 ${rgba(colors.gray, .15)}, 0 2px 10px 0 ${rgba(colors.gray, .02)};
  padding: 18px 20px;
  margin-bottom: 30px;
  border-radius: 15px;
  .career-info {
    margin-top: 10px;
    position: relative;
    .career-info-data {
      .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          margin-left: 5px;
        }
        svg {
          color: ${colors.primary};
        }
      }
    }
    .career-info-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      @media screen and (max-width: 991px) {
        position: relative;
        margin-top: 15px;
      }
    }
  }
`

//Default Card
const CardContainer = styled(CardDiv)`
  padding-inline: 12px;
  &.collapses{
    
    .card-wrap{
      display: none;
      transition:transform 0.3s ease-out;
      height:auto;
      transform:scaleY(0);
      transform-origin:top;
    }
    &.collapse-active{
      .card-wrap{
        display: block;
        transform:scaleY(1);
    }
     
    }
  }
  .card-wrap{
    height: 100%;
  }
  .card-name{
    word-wrap: break-word;
  }
  .card-description{
    ${({ text_align }) => text_align && css`
    text-align: ${text_align};
    padding: ${generalGap.xxl}px;
    padding-top: 0;
    .desc{
      margin-bottom: ${generalGap.md}px;
    }
  `}
  }
`
const CardDescription = styled.div`
  ${({ text_align }) => text_align && css`
    text-align: ${text_align};
  `}
  padding: ${generalGap.xxl}px;
  padding-top: 0;
`

const Desc = styled(Text)`
  
`

const Name = styled(Text)`
   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    margin-bottom: ${generalGap.xl}px;
`


export {
  CardDiv,
  Card,
  CardContent,
  CardDate,
  CardImg,
  Division,
  Career,
  ProductCardWrap,
  ProductCol,
  BackgroundImage,
  CardContainer,
  CardDescription,
  Desc,
  Name
}