export const formVariable = {
  borderRadius: 0,
  borderWidth: 1,
  padding: '7px 10px',
  checkLabel: {
    'position': 'relative',
    'padding-left': '20px',
    'margin-bottom': 0,
    'width': 'auto',
    'text-align': 'left',
    'cursor': 'pointer',
    'user-select': 'none'
  }
}

export const cardVariable = {
  borderRadius: 0,
  xs: '',
  sm: '',
  md: '10',
  lg: '',
  xl: '',
  xxl: '',
  xxxl: ''
}

export const modalVariable = {
  borderRadius: 50
}

export const buttonVariable = {
  borderRadius: {
    xxxl: 45,
    xxl: 40,
    xl: 35,
    lg: 30,
    md: 25,
    sm: 20,
    xs: 15,
    xxs: 10,
    xxxs: 5
  }
}

export const generalGap = {
  xxs: 2,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 14,
  xl: 16,
  xxl: 20,
  xxxl: 40,
  xxxxl: 50
}

export const General = {
  borderRadius: '5px',
  boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.25)',
  fontFamily: `'Montserrat', sans-serif`,
  titleFontFamily: 'Russo One, sans-serif',
  transition: 'all .3s ease-in-out',
  flex: {
    'display': 'flex',
    'align-items': 'center',
    'justify-content': 'center'
  },
  marginAuto: {
    'position': 'absolute',
    'left': '0',
    'right': '0',
    'margin': '0 auto'
  }
}

export const InputGeneral = {
  border: '1px solid',
  borderRadius: '23px',
  padding: '7px 15px',
  transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
  checkLabel: {
    'position': 'relative',
    'padding-left': '20px',
    'margin-bottom': 0,
    'width': 'auto',
    'text-align': 'left',
    'cursor': 'pointer',
    'user-select': 'none'
  }
}

export const TableVariable = {
  borderRadius: 0,
  borderBottomWidth: 1
}

export const RSVariable = {
  nav: {
    img_border_radius: 0
  }
}