import React from 'react'
import { useParams } from 'react-router-dom'
import { ProductInfoTab } from 'theme'

export * from './new_tab'

export const TabCom = props => {

  return (
    <ProductInfoTab>
      {props.children}
    </ProductInfoTab>
  )
}