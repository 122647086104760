import React from 'react'
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md'

import { CardCom, TextCom } from '../../common'

type PaymentCardComProps = {
  item: any
}

export const PaymentCardCom: React.FC<PaymentCardComProps> = ({ item, ...props }) => {
  return (
    <CardCom {...props}>
      {item?.check ? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />}
      <TextCom weight='lg'>{item?.name}</TextCom>
    </CardCom>
  )
}