import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { other as OtherAction } from 'store/actions'
import {languages} from 'key/LangKey'


type Props = {}

export const useOther = (props?: Props) => {
  const other = useSelector(state => state.other, shallowEqual)
  const dispatch = useDispatch()

  return {
    ...other,
    languages,
    OtherAction,
    dispatch,
    useSelector
  }
}