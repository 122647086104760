import { lazy } from 'react'

const errorpage = lazy(() => import('./404'))
const emptycart = lazy(() => import('./emptycart'))
const resultnotfound = lazy(() => import('./notfound'))

const Result = {
  errorpage,
  emptycart,
  resultnotfound
}

export default Result