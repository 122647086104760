import styled from 'styled-components'
import { colors, fontSize, generalGap } from 'theme'
import { fontWeight } from '../../attribute'


const StyledSignupForm = styled.div`
  padding-top: ${generalGap.xxxxl}px;
  .signup-form{
    /* border: 1px solid ${colors.gray2}; */
    /* padding: ${generalGap.xxl+10}px; */
    border-radius: ${generalGap.sm+2}px;
    @media(max-width: 768px){
      padding: ${generalGap.xl}px;
    }
  }
  .signup-card{
    display:block;
    padding: ${generalGap.xxl+10}px;
  }

  .title{
    text-align: center;
    margin-bottom: ${generalGap.xxxxl}px;
  }
  .signup-form{
    margin: auto;
    label{
      font-weight: ${fontWeight.lg};
    }
    input{
      border-radius: ${generalGap.xs}px;
      &::placeholder {
      opacity: 1;
      color: ${colors.text};
      }
    }
    .buttons{
      display: flex;
      flex-direction: column;
      align-items: center;
      .signup-btn{
        display: block;
        width: 60%;
        button{ width: 100%;}
      }
      a{
        text-decoration-color: ${colors.primary};
      }
    }
  }
`

export {
  StyledSignupForm
}