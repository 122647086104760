import { useProduct } from '../../common'

type Props = {}

export const useHandleVariant = (props: Props) => {
  const { getProductById_data, ProductAction, dispatch } = useProduct()
  let variants = getProductById_data?.attributes?.data || props?.data

  const handleVariant = (att_id, sub_id) => {
    let data = {
      att_data: getProductById_data?.attributes?.data,
      att_id,
      sub_id
    }
    dispatch(ProductAction.handleVariant(data))
  }


  return [variants, handleVariant]
}