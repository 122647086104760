import { useProduct, useCart, useOther } from '../../common'

type Props = {}

export const useHandleCart = (props: Props) => {
  const { getProductById_data, ProductAction, dispatch } = useProduct()
  const { createCart_data, getShippingAddress_data, CartAction } = useCart()
  const { langStore } = useOther()
  let selectedShippingAddress = getShippingAddress_data?.selected_item
  let langCode = langStore?.code

  let pos_data = {
    order_id: createCart_data?.order_id,
    langCode
  }

  const handleInitialSelectedAddress = async () => {
    if (selectedShippingAddress) {
      let res = await dispatch(CartAction.joinShippingAndOrder({ ID: selectedShippingAddress?.ID, order_id: createCart_data?.order_id }))
      if (res?.payload?.status === 'success') {
        dispatch(CartAction.getCart())
      }
    }
  }

  const handleCartItemDelete = async (item: object) => {
    if (createCart_data?.order_id) {
      pos_data.product_id = item?.product_id
      await dispatch(CartAction.deleteCart(pos_data))
    }
  }

  const handleCartAllItemDelete = async () => {
    if (createCart_data?.order_id) {
      await dispatch(CartAction.deleteAllInCart(pos_data))
    }
  }

  const handleCreateShippingAddress = async data => {
    let pos_req = {
      mobile: data?.user_phone,
      name: data?.name,
      street: data?.address,
      state_id: data?.state,
      township_id: data?.township,
      lat: data?.map?.latitude,
      long: data?.map?.longitude
    }
    let res = await dispatch(CartAction.createShippingAddress(pos_req))
    return res
  }

  const handleEditShippingAddress = async data => {
    let pos_req = {
      shipping_id: data?.edit_id,
      mobile: data?.user_phone,
      name: data?.name,
      street: data?.address,
      state_id: data?.state,
      township_id: data?.township,
      lat: data?.map?.latitude,
      long: data?.map?.longitude
    }
    let res = await dispatch(CartAction.editShippingAddress(pos_req))
    return res
  }

  const handlePayNow = async data => {
    let pay_now_req = {
      order_id: createCart_data?.order_id,
      acquirer_id: data?.payment_id,
      special_request: data?.request
    }
    let res = await dispatch(CartAction.payNow(pay_now_req))
    return res
  }

  const handleSelectShipping = async item => {
    let res = await dispatch(CartAction.handleSelectShippingAddress(item))
    return res
  }

  const handleDeleteShippingAddress = async item => {
    let res = await dispatch(CartAction.removeShippingAddress(item?.ID))
    return res
  }

  const handleAddToCart = async (product_id, quantity) => {
    let pos_obj = {
      type: 'addtocart',
      item: getProductById_data,
      product_id: Number(product_id),
      qty: quantity || getProductById_data?.quantity
    }
    if (getProductById_data?.attributes?.data?.length > 0) {
      pos_obj.variant_id = getProductById_data?.attributes?.selected_variant_ids?.length > 0 ? getProductById_data?.attributes?.selected_variant_ids : null
    }
    let checkStock_res = await dispatch(ProductAction.checkStock(pos_obj))
    if (checkStock_res?.payload?.in_stock) {
      delete pos_obj.type
      delete pos_obj.item
      pos_obj.langCode = langCode
      if (createCart_data?.order_id) {
        pos_obj.order_id = createCart_data.order_id
      }
      let createCart_res = await dispatch(CartAction.createCart(pos_obj))
      return createCart_res
    }
  }

  return {
    selectedShippingAddress,
    handleInitialSelectedAddress,
    handleAddToCart,
    handleCartItemDelete,
    handleCartAllItemDelete,
    handleCreateShippingAddress,
    handleEditShippingAddress,
    handlePayNow,
    handleSelectShipping,
    handleDeleteShippingAddress
  }
}