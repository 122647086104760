import styled from 'styled-components'
import { rgba } from 'polished'

//* start button component
export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props?.theme?.buttonVariable?.borderRadius?.[props?.btnBorderRadius] || props?.btnBorderRadius || props?.theme?.buttonVariable?.borderRadius.md}px;
  padding: 10px 15px;
  min-width: ${props => props.btnWidth || 150}px;
  min-height: ${props => props.btnHeight || 50}px;
  background-color: ${props => props?.theme?.color?.button?.[props?.bgColor] || props?.bgColor || props?.theme?.color?.button?.primary};
  border: ${props => (props?.type === 'outline' || props?.outline) ? 1 : 0}px solid ${props => props?.theme?.color?.button[props?.borderColor || 'transparent'] || props?.borderColor};
  transition: background-color .15s ease-in-out;

  &:hover {
    background-color: ${props => {
    if ((props?.type === 'outline' || props?.outline)) {
      return props?.theme?.color?.button?.[props?.hoverColor] || props?.hoverColor || props?.theme?.color?.button?.gray3
    } else {
      return rgba(props?.theme?.color?.button?.[props?.bgColor] || props?.bgColor || props?.theme?.color?.button?.primary, .7)
    }
  }};
  }
  &.btn-disabled {
   
  }
  &.btn-link {
   background: transparent;
   min-width: 0;
   min-height: 0;
  }
`

export const StyledBtnSvgL = styled.div`
  margin-right: 15px;
`

export const StyledBtnSvgR = styled.div`
  margin-right: 15px;
`
//* end button component

//* addToCart Btn
export const StyledAddToCartBtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${props => props?.btnBorderColor || props?.theme?.color?.button?.[props.btnBorderColor || 'borderColor']};
  padding: 0 2px;
  border-radius: ${props => props?.btnBorderRadius || props?.theme?.buttonVariable?.borderRadius?.md}px;
  max-width: 135px;
`

export const StyledAddToCartBtnLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .left_btn_wrap{
    background-color: ${props => props?.leftbtnbgcolor || props?.theme?.color?.button?.[props.leftbtnbgcolor || 'borderColor']};
    border-radius: 50px;
    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transaction: background-color .15s ease-in-out;

    :hover{
      background-color: ${props => rgba(props?.theme?.color?.button?.borderColor, .6)};
    }
  }
`

export const StyledAddToCartBtnCenter = styled.div`
  flex: 1
`

export const StyledAddToCartBtnRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  .right_btn_wrap{
    background-color: ${props => props?.rightbtnbgcolor || props?.theme?.color?.button?.[props.rightbtnbgcolor || 'borderColor']};
    border-radius: 50px;
    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    transaction: background-color .15s ease-in-out;

    :hover{
      background-color: ${props => rgba(props?.theme?.color?.button?.borderColor, .6)};
    }
  }
`
//* end addToCart Btn