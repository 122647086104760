import Product from 'screens/product'
import Home from 'screens/home'
import Cart from 'screens/cart'
import Static from 'screens/static'
import Auth from 'screens/auth'
import Account from 'screens/account'
import Common from 'screens/common'
import Result from 'screens/result'
import { PrivateRoute } from './router'

const Routes = [
  {
    path: '/',
    element: <Home.home />
  },
  // {
  //   path: '/category',
  //   element: <Product.categorylist />
  // },
  // {
  //   path: '/category/:catId',
  //   element: <Product.category />
  // },
  {
    path: '/product',
    element: <Product.productlist />
  },
  {
    path: '/product/:id',
    element: <Product.productdetail />
  },
  {
    path: '/promotion/:id',
    element: <Product.promotion />
  },
  {
    path: '/order/cart',
    element: <Cart.cart />
  },
  {
    path: '/order/checkout',
    element: <Cart.checkout />
  },
  {
    path: '/order/:id/complete/:status',
    element: <Common.ordersuccess />
  },
  {
    path: '/aboutus',
    element: <Static.aboutus />
  },
  {
    path: '/contactus',
    element: <Static.contactus />
  },
  {
    path: '/page/:pageCode',    //for static screens
    element: <Static.staticpage />
  },
  {
    path: '/user/signin',
    element: <Auth.signin />
  },
  {
    path: '/user/signup',
    element: <Auth.signup />
  },
  {
    path: '/user/signup/success',
    element: <Auth.success />
  },
  {
    path: 'user/forgot_password',
    element: <Auth.forgotpwd />
  },
  {
    path: 'user/otp',
    element: <Auth.otp />
  },
  {
    path: '/user/reset_password',
    element: <Auth.resetpwd />
  },
  {
    path: '/user/reset_password/success',
    element: <Auth.success />
  },
  {
    path: '/user/change_password',
    element: <Auth.changepwd />
  },
  {
    path: '/user/profile',
    protect: true,
    element: <Account.profile />
  },
  {
    path: '/user/profile_edit',
    protect: true,
    element: <Account.profileEdit />
  },
  {
    path: '/user/my_orders',
    protect: true,
    element: <Account.orderlist />
  },
  {
    path: '/user/my_orders/:orderId',
    protect: true,
    element: <Account.orderdetail />
  },
  {
    path: '/user/my_orders/:orderId/review/:productId',
    protect: true,
    element: <Account.review />
  },
  {
    path: '/user/wishlist',
    protect: true,
    element: <Account.wishlist />
  },
  {
    path: '/search/:keyword',
    element: <Product.productsearchlist />
  },
  {
    path: '/common',
    element: <Common.common />
  },
  {
    path: '*',
    element: <Result.errorpage />
  }
]

export default Routes