import React from 'react'

import { TextCom } from '../../common'
import { moneyFormat, en2mm_num } from 'utils'
import { useProduct, useHandleOther } from 'hook'

type PricehandleModProps = {

}
export const PriceHandleMod: React.FC<PricehandleModProps> = props => {
  const { getProductById_data } = useProduct()
  const { langStore, translate } = useHandleOther()
  let langCode = langStore.code

  return (
    getProductById_data?.promo_price ?
      <div style={{ flexDirection: 'row', alignItems: 'center' }}>
        {Number(getProductById_data?.promo_price) > 0 && <TextCom weight='lg' color='primary' style={{ marginRight: 10 }}>{moneyFormat(Math.ceil(getProductById_data?.promo_price), langCode)} {translate('ks', 'Ks')}</TextCom>}
        <TextCom size='sm' color='desc' style={{ textDecorationLine: 'line-through', marginRight: 10 }}>{moneyFormat(Math.ceil(getProductById_data?.extra_price_for_variant || getProductById_data?.price), langCode)} {translate('ks', 'Ks')}</TextCom>
        <div style={{
          paddingTop: 2,
          paddingRight: 5,
          paddingBottom: 2,
          paddingLeft: 5,
          borderRadius: 5
        }}>
          <TextCom size='xxs' weight='lg'>-{en2mm_num(getProductById_data?.cal_discount_percent, langCode)}%</TextCom>
        </div>
      </div>
      :
      <>
        {Number(getProductById_data?.price) > 0 && <TextCom size='lg' weight='xl' color='primary'>{moneyFormat(Math.ceil(getProductById_data?.extra_price_for_variant || getProductById_data?.price), langCode)} {translate('ks', 'Ks')}</TextCom>}
      </>
  )
}