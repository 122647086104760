import React from "react";
import { CardContainer, CardDescription, CardImg, Name, StyledCardContainer } from "../../../theme";
import { ButtonCom } from "../button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TextEllipsis } from "components";
import { CgShoppingCart } from "react-icons/cg";
import { useHandleOther } from "../../../hook";
import Result from "../../../screens/result";
import { TextCom } from "../typo";

export const Card = (props) => {
  const { dictionary_data, langStore } = useSelector((state) => state.other);
  const { translate } = useHandleOther();
  const langCode = langStore?.code;
  let isSlider = props.isSlider || false;
  let collapse = props.isCollapse || false;
  const Col = () => {
    if (isSlider === false) {
      return ` ${collapse
        ? "collapses"
        : "card-container col-6 col-sm-6 col-md-4 col-lg-3"
        }`;
    }
  };
  const CollapseToggle = (e) => {
    e.target.offsetParent.classList.toggle("collapse-active");
  };

  return (
    <CardContainer className={Col()} {...props} border>
      {props.collapseName && (
        <TextEllipsis onClick={(e) => CollapseToggle(e)}>
          {props.collapseName}
        </TextEllipsis>
      )}
      <div className="card-wrap">
        <Link to={`${props?.link}`} className="card-link" />
        {props.image && <CardImg src={props.image} top />}
        {props.children && !props.image ? (
          props.children
        ) : (
          <CardDescription {...props} className="card-description">
            {props.cardName && (
              <>
                <Name color="desc" weight="md" className="card-name">
                  {props.cardName}
                </Name>
              </>
            )}
            {props.desc && (
              <TextEllipsis className="desc" size="sm">
                {props.desc}
              </TextEllipsis>
            )}
            {props.buttonText && (
              <Link to={props?.link}>
                {/* <Button primary productBtn className="detail-btn">
                  <span>{translate(props.buttonText, "More detail")}</span>
                  <CgShoppingCart />
                </Button> */}
              </Link>
            )}

            {props.children}
          </CardDescription>
        )}
      </div>
    </CardContainer>
  );
};

type CardComProps = {
  children: any
}
export const CardCom: React.FC<CardComProps> = props => {
  return (
    <StyledCardContainer {...props}>
      {
        props?.children ?
          props?.children
          :
          <TextCom weight='lg' textAlign='center'>Please insert children!</TextCom>
      }
    </StyledCardContainer>
  )
}