import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { SidebarContainer } from "theme";
import { getUiTranslate, moneyFormat } from "../../../utils";
import { useHandleOther } from "hook";

export const SideBar = ({ profile, terms, options, activelink }) => {
  const { translate } = useHandleOther();

  return (
    <SidebarContainer>
      {profile ? (
        <>
          <li>
            <NavLink
              className={activelink === "profile" ? "active" : ""}
              to="/user/profile"
            >
              {translate("my-profile", "My profile")}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={activelink === "order" ? "active" : ""}
              to="/user/my_orders"
            >
              {translate("my-order", "My order")}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={activelink === "wishlist" ? "active" : ""}
              to="/user/wishlist"
            >
              {translate("my-wishlist", "My wishlist")}
            </NavLink>
          </li>
        </>
      ) : terms ? (
        <>
                  <li>
            <NavLink
              className={activelink === "/privacy_policy" ? "active" : ""}
              to="/page/privacy_policy"
            >
              {translate("privacy-policy", "Privacy Policy")}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={activelink === "/terms_and_condition" ? "active" : ""}
              to="/page/terms_and_condition"
            >
              {translate("terms_and_condition", "Terms and conditions")}
            </NavLink>
          </li>
{/* 
          <li>
            <NavLink
              className={
                activelink === "/returnandrefundpolicy" ? "active" : ""
              }
              to="/returnandrefundpolicy"
            >
              {translate("refund_policy", "Refund policy")}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={activelink === "/deliveryinfo" ? "active" : ""}
              to="/deliveryinfo"
            >
              {translate("deli_info", "Deli info")}
            </NavLink>
          </li>
          <li>
            <NavLink
              className={activelink === "/paymentmethod" ? "active" : ""}
              to="/paymentmethod"
            >
              {translate("payment_method", "Payment method")}
            </NavLink>
          </li> */}
        </>
      ) : (
        <>
          {options.map((item) => {
            
            return (
              <li>
                <NavLink activeclassname="active" to={`/${item.link}`}>
                  {translate(item.name, "") || item.name}
                </NavLink>
              </li>
            );
          })}
        </>
      )}
    </SidebarContainer>
  );
};
