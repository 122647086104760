import styled from "styled-components";
import { StyledSignin } from "./Signin";

const StyledResetForgetPwd = styled(StyledSignin)`
  .header {
    margin-bottom: 50px;
  }
  .sub {
    margin-bottom: 30px;
  }
  .second-link-group {
    button {
      p {
        border-bottom: 0;
        margin: 0;
      }
    }
  }
`;
export { StyledResetForgetPwd };
