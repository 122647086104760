import React from 'react'
import Pagination from 'rc-pagination'

import { TextCom } from '../typo'
import { StyledPagination } from 'theme'

export type PaginationProps = {
  onChange: any,
  current: number,
  total: number,
  defaultPageSize: number
}

export const PaginationCom: React.FC<PaginationProps> = props => {
  const { total, defaultPageSize } = props

  return (
    <StyledPagination className={`${total > defaultPageSize ? '' : 'hide'}`}>
      <Pagination
        showLessItems={true}
        showTitle={false}
        prevIcon={<TextCom size='sm'>PREV</TextCom>}
        nextIcon={<TextCom size='sm'>NEXT</TextCom>}
        {...props}
      />
    </StyledPagination>
  )
}