import styled, { css } from 'styled-components'
import {
  fontSize,
  fontWeight,
  colors,
} from '../../attribute'

const Color = styled.p`
  color: ${colors.text};
  ${({ color }) =>
    color &&
    css`
      color: ${colors[color]};
    `}
  ${({ bg }) =>
    bg &&
    css`
      background: ${bg || colors.secondary};
    `}
`;

export const Text = styled(Color)`
  font-size: ${fontSize.md}px;
  font-weight: ${fontWeight.md};
  line-height: ${fontSize.md * 1.618}px;
  margin: 0;
  ${({ size }) =>
    size &&
    css`
      font-size: ${fontSize[size] || size}px;
      line-height: ${fontSize[size] || size * 1.618}px;
    `}
  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${fontWeight[weight]};
    `}
  ${({ cu_size }) =>
    cu_size &&
    css`
      font-size: ${cu_size}px;
    `}
  ${({ cu_weight }) =>
    cu_weight &&
    css`
      font-weight: ${cu_weight};
    `}
  ${({ text_transform }) =>
    text_transform &&
    css`
      text-transform: ${text_transform};
    `}
  ${({ text_align }) =>
    text_align &&
    css`
      text-align: ${text_align};
    `}
  color: ${colors.text};
  ${({ color }) =>
    color &&
    css`
      color: ${colors[color] || color};
    `}
`;

export const lineHeight = (fontSize, props) => {
  // const multiplier = (fontSize > 20) ? (props.theme?.langStore?.code === 'en' ? 1 : 1.88) : (props.theme?.langStore?.code === 'en' ? 0.5 : 0.88);
  const multiplier = (fontSize > 20) ? 1 : 0.5;
  // console.log('fontSize', fontSize, parseInt(fontSize + (fontSize * multiplier), 10))
  return parseInt(fontSize + (fontSize * multiplier), 10);
}

export const handleFontFamily = props => {
  let lang_data = {
    my: `'Montserrat', sans-serif`,
    en: `'Montserrat', sans-serif`
  }
  let tmp = lang_data[props.theme.langStore.code || 'en']
  return tmp
}

export const StyledText = styled.p`
  /* line-height: ${props => (props.theme.fontSize[props?.size] || props.theme.fontSize.md) * 1.7}px; */
  line-height: ${props => lineHeight(props.theme.fontSize[props?.size] || props?.size || props.theme.fontSize.md, props)}px;
  font-size: ${props => {
    let size = props.theme.fontSize[props?.size] || props.size || props.theme.fontSize.md;
    /* return size */
    return size
    /* return Dimensions.get('window').width < 550 ? size / 1.1 : size */
  }}px;
  font-weight: ${props => props.theme.fontWeight[props?.weight] || props?.weight || props.theme.fontWeight.md};
  color: ${props => props.theme.color.typo[props?.color] || props?.color || props.theme.color.typo.text};
  text-align: ${props => props.textAlign || 'left'};
  font-family: ${props => {
    let res = handleFontFamily(props)
    /* console.log('font res', res) */
    return res
  }};
  margin: 0;
  text-decoration: ${props => props?.textDecoration || (props?.link ? 'underline' : 'auto')};
  text-decoration-color: ${props => props?.link ? (props.theme.color.typo[props?.color] || props?.color || props.theme.color.typo.text) : 'auto'};

  &.middle_txt {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${props => props?.theme?.color?.typo?.gray};
    line-height: .1em;
    margin: 20px 0;

    span {
      background: #fff;
      padding: 0 30px;
    }
  }
`