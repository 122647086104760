import styled from "styled-components";
import {
  InputGeneral,
  fontWeight,
  fontSize,
  media,
  colors,
} from "../../attribute";
import Icon from "assets/icons/demo";
const imgUrl = {
  leftArrow: Icon.leftArrow,
  rightArrow: Icon.rightArrow,
};

export const StyledTableContainer = styled.table`
  width: 100%;
  border-radius: ${(props) => props?.theme?.TableVariable?.borderRadius}px;
`;

export const StyledTableHead = styled.thead``;

export const StyledTr = styled.tr`
  border-bottom: ${(props) =>
    `${props?.theme?.TableVariable?.borderBottomWidth || 1}px solid ${props?.theme?.color?.table?.gray2
    }`};
`;

export const StyledTh = styled.th`
  width: ${(props) => `${props?.width}px` || "auto"};
  text-align: ${(props) => `${props?.placement || "auto"}`};
  padding: 10px 0;
`;

export const StyledTableBody = styled.tbody``;

export const StyledTd = styled.td`
  width: ${(props) => `${props?.width}px` || "auto"};
  text-align: ${(props) => `${props?.placement || "auto"}`};
  padding: 10px 0;
`;

export const StyledTf = styled.tfoot``;

export const StyledPagination = styled.div`
  margin: 20px 0;
  align-items: center;
  justify-content: center;
  display: flex;
    
  &.hide {
    display: none;
  }
  .rc-pagination {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
   

    li {
      cursor: pointer;
      border: 1px solid ${props => props?.theme?.color?.table?.gray || '#ccc'};
      &:focus {
        outline: none;
      }
      a,
      button {
        text-decoration: none;
        position: relative;
        display: block;
        padding: 0.5rem;
        margin: 0;
        line-height: 1.25;
        background-color: transparent;
        border: 1px solid transparent;
        color: ${props => props?.theme?.color?.table?.gray || '#ccc'};
        font-weight: 500;
        transition: all 0.2s;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: ${props => props?.theme?.color?.table?.primary || '#000'};
          color: ${props => props?.theme?.color?.table?.light || '#fff'};
        }
      }
      &.rc-pagination-disabled {
        a,
        button {
          background-color: transparent;
          cursor: default;
        }
      }
      button {
        &:focus {
          outline: none;
        }
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          display: inline-block;
        }
      }
      &.rc-pagination-item-active {
        border: 1px solid ${props => props?.theme?.color?.table?.primary || '#000'};
        a {
          background: ${props => props?.theme?.color?.table?.primary || '#000'};
          color: ${props => props?.theme?.color?.table?.light || '#fff'};
        }
      }
      &.rc-pagination-prev {
        padding: 4.5px;
        button {
          margin: 0;
          margin-right: 0;
          &:before {
            background: url(${imgUrl.leftArrow}) no-repeat center/cover;
          }
          &:hover {
            &:before {
              background: url(${imgUrl.leftArrow}) no-repeat center/cover;
            }
          }
        }
      }
      &.rc-pagination-prev.rc-pagination-disabled {
        cursor: not-allowed;
        p{
          color: #ccc;
        }
        button {
          &:hover {
            &:before {
              background: url(${imgUrl.leftArrow}) no-repeat center/cover;
            }
          }
          &:before {
            opacity: 0.4;
          }
        }
      }
      &.rc-pagination-next.rc-pagination-disabled {
        cursor: not-allowed;
        p{
          color: #ccc;
        }
        button {
          &:hover {
            &:before {
              background: url(${imgUrl.rightArrow}) no-repeat center/cover;
              opacity: 0.5;
            }
          }
          &:before {
            opacity: 0.4;
          }
        }
      }
      &.rc-pagination-next {
        padding: 4.5px;
        button {
          margin: 0;
          margin-left: 0;
          &:before {
            background: url(${imgUrl.rightArrow}) no-repeat center/cover;
          }
          &:hover {
            &:before {
              background: url(${imgUrl.rightArrow}) no-repeat center/cover;
            }
          }
        }
      }
      &.rc-pagination-jump-prev,
      &.rc-pagination-jump-next {
        button {
          &:before {
            content: "...";
          }
        }
      }
    }
  }
`;
