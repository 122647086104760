import React from 'react'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowRight } from 'react-icons/md'

import { StyledBreadcrumbCon, StyledBreadcrumbItemCon, StyledBreadcrumbItem } from 'theme'

type BreadcrumbComProps = {

}

export const BreadcrumbCom: React.FC<BreadcrumbComProps> = props => {
  const { data, isContainer = true } = props

  return (
    <StyledBreadcrumbCon>
      <div className={isContainer ? 'container' : 'container-fluid'} >
        <StyledBreadcrumbItemCon>
          {
            data?.length > 0 && data?.map((x: any, i: number) => (
              <StyledBreadcrumbItem key={i} {...x}>
                <Link to={x?.route || '/'} {...x}>{x?.name}</Link>
                <MdKeyboardArrowRight />
              </StyledBreadcrumbItem>
            ))
          }
        </StyledBreadcrumbItemCon>
      </div>
    </StyledBreadcrumbCon>
  )
}