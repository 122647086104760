import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ModalCom, ButtonCom, TextCom } from '../../common'
import { StyledHeadCon, StyledHeadWrap } from 'theme'
import { useHandleWishlist } from 'hook'
import Icon from 'assets/icons/demo'

type WishlistButtonModProps = {}

export const WishlistButtonMod: React.FC<WishlistButtonModProps> = ({ product_id, ...props }) => {
  let navigate = useNavigate()
  const { exist, handleIsExistWishlist } = useHandleWishlist()
  const [state, setState] = useState({
    modalVisible: false
  })

  const handleExist = async () => {
    let res = await handleIsExistWishlist({ product_id })
    if (res?.required_login) {
      handleModal(true)
    }
  }

  const handleRouteChange = (route, params) => {
    navigate(route, { ...params })
  }

  const handleModal = (modalVisible) => {
    setState({
      ...state,
      modalVisible
    })
  }

  return (
    <>
      <StyledHeadCon onClick={handleExist}>
        <StyledHeadWrap>
          {exist ? <img src={Icon.ActiveHeartSvg} /> : <img src={Icon.HeartSvg} />}
        </StyledHeadWrap>
        <TextCom>Add to wishlist</TextCom>
      </StyledHeadCon>
      <ModalCom
        isModal={state.modalVisible}
        handleIsModal={handleModal}
      >
        <div style={{ position: 'relative' }}>
          <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 26, paddingLeft: 10, paddingRight: 10 }}>
            <div style={{ marginBottom: 10 }}>

            </div>
            <TextCom style={{ textAlign: 'center', marginBottom: 25 }}>Please Login Wishlist</TextCom>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonCom
                text='Login'
                color='light'
                onClick={() => handleRouteChange('/user/signin')}
              />
            </div>
          </div>
        </div>
      </ModalCom>
    </>
  )
}