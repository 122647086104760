import React, { useEffect, useState } from 'react'

import { StyledTabCon, StyledTabHeadCon, StyledTabHeadItemCon, StyledTabBodyCon, StyledTabBodyWrap } from 'theme'
import { TextCom } from '../typo'

type NewTabComProps = {
  defaultActiveKey: string
}
export const NewTabCom: React.FC<NewTabComProps> = props => {
  const [saveTabName, setSaveTabName] = useState(props?.defaultActiveKey)

  useEffect(() => {
    document.getElementById(`ht_${props?.defaultActiveKey}`)?.click()
  }, [props?.defaultActiveKey])

  const openTab = (evt, tabname) => {
    var tabcontent = document.querySelectorAll('.tabcontent')
    var tabheaditem = document.querySelectorAll('.tabheaditem')
    for (let i = 0; i < tabcontent.length; i++) {
      const item = tabcontent[i]
      item.classList.remove('active')
    }
    for (let i = 0; i < tabheaditem.length; i++) {
      const item = tabheaditem[i]
      item.className = item.className.replace(' active', '')
    }
    document.getElementById(`bt_${tabname}`)?.classList.add('active')
    evt.currentTarget.className += ' active'
    setSaveTabName(tabname)
  }

  return (
    <StyledTabCon>
      {
        props?.children ?
          props?.children
          :
          <>
            <TabHeadCom activeTabName={saveTabName} openTab={openTab} {...props} />
            <TabBodyCom activeTabname={saveTabName} {...props} />
          </>
      }
    </StyledTabCon>
  )
}

type TabHeadComProps = {
  openTab: any,
  dataSource: any,
  activeTabName: string
}
export const TabHeadCom: React.FC<TabHeadComProps> = ({ openTab, activeTabName, ...props }) => {

  return (
    <StyledTabHeadCon>
      {
        props?.children ?
          <StyledTabHeadItemCon id={`ht_${props?.key}`} className='tabheaditem' onClick={e => openTab(e, props?.key)}>
            <div className={`def_title_wrap ${activeTabName === props?.key ? 'active' : undefined}`}>
              {props?.children}
            </div>
          </StyledTabHeadItemCon>
          :
          props?.dataSource?.length > 0 &&
          props?.dataSource?.map((x, i) => (
            <StyledTabHeadItemCon id={`ht_${x?.key}`} className='tabheaditem' key={i} onClick={e => openTab(e, x.key)}>
              {
                x?.header_render ?
                  x?.header_render(x, i, activeTabName === x.key)
                  :
                  <div className={`def_title_wrap ${activeTabName === x.key ? 'active' : undefined}`}>
                    <TextCom weight='xl' textAlign='center'>{x.title}</TextCom>
                  </div>
              }
            </StyledTabHeadItemCon>
          ))
      }
    </StyledTabHeadCon>
  )
}


type TabBodyComProps = {
  dataSource: any,
  activeTabName: string
}
export const TabBodyCom: React.FC<TabBodyComProps> = ({ activeTabName, ...props }) => {

  return (
    <StyledTabBodyCon>
      {
        props?.children ?
          <StyledTabBodyWrap id={`bt_${props?.key}`} className='tabcontent'>
            {props?.children}
          </StyledTabBodyWrap>
          :
          props?.dataSource?.length > 0 &&
          props?.dataSource?.map((x, i) => (
            <StyledTabBodyWrap id={`bt_${x?.key}`} className='tabcontent'>
              {
                x?.body_render ?
                  x?.body_render(x, i, activeTabName === x.key)
                  :
                  <TextCom {...x}>{x?.desc}</TextCom>
              }
            </StyledTabBodyWrap>
          ))
      }
    </StyledTabBodyCon>
  )
}

type TabPannelCom = {
  tabName: string
}
export const TabPanelCom: React.FC<TabPannelCom> = ({ tabName, ...props }) => {
  return (
    <>
      <TabHeadCom {...props}>{tabName}</TabHeadCom>
      <TabBodyCom {...props}>{props?.children}</TabBodyCom>
    </>
  )
}