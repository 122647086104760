import { useOther, useHandleOther } from "../../../hook";
import { FiChevronDown } from 'react-icons/fi'
import { TextCom } from "components";
import { SwitchWrapper } from "theme";


const LanguageSwitch = _ => {
    const { langStore } = useOther()
    const { languages, handleChangeLanguage } = useHandleOther()

    const handleCheck = (e, value) => {
        handleChangeLanguage(value)
        e.target.classList.toggle('active')
    }

    return (
        <SwitchWrapper>
            <div className="language-switch">
                <img src={langStore?.lan_icon} alt='my' />
                <TextCom size="sm" weight="lg">{langStore?.lang}</TextCom>
                <FiChevronDown />
                <ul className="options">
                    {
                        languages.length > 0 && languages.map((lang, i) => {
                            return (
                                <li
                                    className={`${langStore?.code === lang?.code ? 'active' : ''}`} key={i}
                                    onClick={(e) => handleCheck(e, lang)}
                                    key={i}>
                                    <img src={lang?.lan_icon} alt={lang?.lang} />
                                    <TextCom size="sm">{lang.lang}</TextCom>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

        </SwitchWrapper>
    )
}

export default LanguageSwitch;
