import styled from 'styled-components'

export const StyledTabCon = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTabHeadCon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid ${props => props?.theme?.color?.header?.gray2};
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  };
`

export const StyledTabHeadItemCon = styled.div`
  cursor: pointer;

  &.active {
    border-bottom: 1px solid ${props => props?.theme?.color?.header?.primary};
  }

  .def_title_wrap{
    padding: 10px;
    transition: opacity .3s ease-in-out;

    :not(.active){
      &:hover{
        opacity: .5;
      }
    }
  }
`

export const StyledTabBodyCon = styled.div`
  padding: 20px 0;
`

export const StyledTabBodyWrap = styled.div`
  display: none;
  
  &.active{
    display: block;
  }
`