import styled from 'styled-components'

export const StyledBreadcrumbCon = styled.div`
  padding: 15px;
  margin-bottom: 20px;
  background-color: ${props => props?.theme?.colors[props?.bgColor || 'gray3'] || props?.bgColor};
  @media(max-width: 991px){
    padding: 10px;
  }
`

export const StyledBreadcrumbItemCon = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledBreadcrumbItem = styled.li`
  font-size: ${props => props.theme.fontSize[props?.size] || props.size || props.theme.fontSize.sm}px;
  position: relative;
  display: flex;
  align-items: center;
  a{
    color: ${props => props?.theme?.colors?.text};
    text-decoration: none;
    opacity: ${props => props?.route ? 'auto' : .7};
    pointer-events: ${props => props?.route ? 'auto' : 'none'};
    cursor: ${props => props?.route ? 'pointer' : 'default'};
    transition: all 0.3s ease;

    :hover{
      color: ${props => props?.theme?.colors.primary};
    }
  }
  svg{
    margin-inline: 2px;
    color: ${props => props?.theme?.colors.desc}
  }

  :last-child{
    svg{
      display: none;
    }
  }
`