import Icon from 'assets/icons/demo'
export const languages = [
  {
    lang: 'English',
    code: 'en',
    lan_icon: Icon.engImage
  },
  {
    lang: 'မြန်မာ',
    code: 'my',
    lan_icon: Icon.myImage
  }
]
