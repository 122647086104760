import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { Loading } from 'components'
import RouteConfig from './route.config'
import { useAuth } from 'hook'

export const PrivateRoute = ({ children }) => {
  const { getProfile_data } = useAuth()

  if (!getProfile_data) {
    return <Navigate to='/' />
  }

  return children
}

const RouteList = () => {
  return (
    <Routes>
      {RouteConfig.map(({ path, element, protect }, key) => {
        return (
          <Route path={path} key={key} element={protect ? <PrivateRoute>{element}</PrivateRoute> : element} />
        )
      })}
    </Routes>
  )
}

export default function AppRoute() {

  const location = useLocation()

  React.useLayoutEffect(_ => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Suspense fallback={<Loading pageLoading={true} />}>
      <RouteList />
    </Suspense>
  )
}

{/* <Suspense fallback={<Loading pageLoading={true} />}></Suspense> */ }