import styled, { css } from 'styled-components'
import { fontSize, fontWeight, General, InputGeneral, colors, generalGap } from '../../attribute'

import { handleFontFamily } from '../typo'

const StyledInputGroup = styled.div`
  position: relative;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  &.custom-check {
    display: flex;
    align-items: center;
    input {
      opacity: 0;
      position: absolute;

      &[type='checkbox'] {
        &:checked {
          + label {
            &:before {
              background-color: ${colors.primary};
            }
            &:after {
              opacity: 1;
            }
          }
        }

        &:not(:checked) {
          + label {
            &:before {
              background-color: ${colors.light};
            }
            &:after {
              opacity: 0;
            }
          }
        }
      }
    }

    label {
      ${InputGeneral.checkLabel}

      &:before {
        content: '';
        position: absolute;
        top: 5px;
        margin: auto;
        left: 0;
        border: 1px solid ${colors.primary};
        border-radius: 0.15rem;
        width: 15px;
        height: 15px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 6px;
        margin: auto;
        left: 5px;
        width: 5px;
        height: 10px;
        border: 1px solid ${colors.lightt};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  &.custom-radio {
    display: flex;
    align-items: center;
    input {
      opacity: 0;
      position: absolute;

      &[type='radio'] {
        &:checked {
          + label {
            &:before {
              opacity: 1;
            }
          }
        }

        &:not(:checked) {
          + label {
            &:before {
              opacity: 0;
            }
          }
        }
      }
    }

    label {
      ${InputGeneral.checkLabel}

      &:before,
      &:after {
        content: '';
        display: block;
        border-radius: 15px;
        position: absolute;
        transition: all 0.3s ease;
      }

      &:before {
        width: 9px;
        height: 9px;
        background: ${colors.primary};
        top: 8px;
        left: 3px;
      }

      &:after {
        width: 15px;
        height: 15px;
        border: 1px solid ${colors.primary};
        left: 0;
        top: 5px;
      }
    }
  }

  svg {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: -22px;
    margin: auto 0;
  }
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  padding: ${props => props?.theme?.formVariable?.padding || '7px 15px'};
  font-size: ${props => props.theme.fontSize[props?.size] || props.size || props.theme.fontSize.md}px;
  font-weight: ${props => props.theme.fontWeight[props?.weight] || props?.weight || props.theme.fontWeight.md};
  color:${props => props.theme.color.typo[props?.inputTxtColor] || props?.inputTxtColor || props.theme.color.typo.text};
  background-color: ${props => props.theme.color.form[props?.inputBgColor] || props?.inputBgColor || props.theme.color.form.inputBgColor};
  background-clip: padding-box;
  border: ${props => props?.inputBorderWidth || 1}px solid ${props => props?.theme?.color?.form?.[props?.inputBorderColor] || props?.inputBorderColor || props?.theme?.color?.form?.borderColor};
  border-radius: ${props => props?.theme?.formVariable?.borderRadius}px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  outline: 0;
  font-family: ${props => {
    let res = handleFontFamily(props)
    return res
  }};
  &.qty-input{
    border-width: 0;
    padding: 0;
    text-align: center;
  }
  &:disabled {
    background: transparent;
  }
  &.error {
    border: 1px solid ${colors.danger};
  }

  &:focus {
    border-color: ${colors.gray};
  }

  &:placeholder {
    opacity: 0.3;
    font-weight: 400;
  }

  &[type='checkbox']{
    cursor: pointer;
    padding: 0;
    appearance: none;
    background-color: ${props => props?.theme?.color?.form?.light || '#fff'};
    margin: 0;
    width: 15px;
    height: 15px;
    border: 1px solid ${props => props?.theme?.color?.form?.gray || '#DDDD'};

    display: grid;
    place-content: center;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      border-radius: 0.15rem;
      width: 15px;
      height: 15px;
    }
  }

  &[type='checkbox']:checked::before{
    content: '';
    position: absolute;
    top: -3px;
    bottom: 0;
    margin: auto;
    left: 5px;
    width: 5px;
    height: 10px;
    border: 1px solid ${props => props?.theme?.color?.form?.dark || '#000'};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &[type=number]::-webkit-inner-spin-button, 
  &[type=number]::-webkit-outer-spin-button {  
    opacity: 1;
  }
`;

const Label = styled.label`
  max-width: 100%;
  color: ${colors.label};
  font-size: ${fontSize.md}px;
  font-weight: ${fontWeight.xl};
  white-space: wrap;
  overflow: hidden;
  margin-bottom: 5px;
`;

const Span = styled.span`
  position: absolute;
  top: 18.5px;
  margin: auto 0;

  ${({ InputPhone }) =>
    InputPhone &&
    css`
      color: ${colors.text};
      font-size: ${fontSize.md}px;
      font-weight: ${fontWeight.md};
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      transform: translateY(13px);
      ${'' /* &:before { // removed country code as a customer request
      content: '+95';
    } */
      }
    `}
`;

const ErrorMessage = styled.span`
  position: absolute;
  color: ${colors.danger};
  font-size: ${fontSize.sm}px;
  display: inline-block;
  margin-left: 15px;
  line-height: 20px;
`;

const Error = styled.p`
  color: ${colors.danger};
  font-size: ${fontSize.sm}px;
  margin: 0;
`;

const StyledCheckBox = styled.div`
  &.custom-check {
    display: flex;
    align-items: center;
    input {
      opacity: 0;
      position: absolute;

      &[type='checkbox'] {
        &:checked {
          + label {
            &:before {
              background-color: ${colors.primary};
            }
            &:after {
              opacity: 1;
            }
          }
        }

        &:not(:checked) {
          + label {
            &:before {
              background-color: ${colors.light};
            }
            &:after {
              opacity: 0;
            }
          }
        }
      }
    }

    label {
      ${InputGeneral.checkLabel}

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        border: 1px solid ${colors.primary};
        border-radius: 0.15rem;
        width: 15px;
        height: 15px;
      }

      &:after {
        content: '';
        position: absolute;
        top: -3px;
        bottom: 0;
        margin: auto;
        left: 5px;
        width: 5px;
        height: 10px;
        border: 1px solid ${colors.light};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
`;

const Numinput = styled.input`
  padding-inline: ${generalGap.md}px;
  ${({ radius }) => radius && css`
    border-radius: ${generalGap[radius]}px!important;
  `}
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button{
    width: 1em;
    opacity: .5; /* shows Spin Buttons per default (Chrome >= 39) */
  }
`


//* new ui styled component

export const StyledFieldWrapper = styled.div`
  position: relative;

  &.vlc_con{
    margin-bottom: 30px;
  }

  textarea{
    height: auto;
  }

  &[type='checkbox'] {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    .label_txt{
     margin-left: 7px;
    }
  }

  .err_msg{
    position: absolute;
    bottom: 10;
    min-height: 18px;
    margin-left: 10px;
  }

  .label_txt{
    margin-right: 5px;
  }
`

export const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledInputContainer = styled.div`

`

export { StyledInputGroup, StyledInput, Label, Span, Error, ErrorMessage, StyledCheckBox, Numinput };