import React from "react"
import { Link, useNavigate } from "react-router-dom"
import Media from "react-media"
import { FiShoppingCart, FiUser, FiPhoneCall, FiLogOut, FiChevronRight } from "react-icons/fi";

import PrimaryNav from "./PrimaryNav"
import SecondaryNav from "./SecondaryNav"
import SearchCom from "./components/SearchCom"
import { HeaderContainer, MobileTopNav, MobileDrawer } from 'theme'
import { TextCom, CollapseCom } from '../../components'
import { useHandleOther, useAuth, useProduct, useOther, useCart } from "hook"

const Header = props => {
    const navigate = useNavigate();
    const { translate } = useHandleOther();
    const { getMetaData_data, getContactUsPage_data, dispatch } = useOther();
    const { getProfile_data, AuthAction } = useAuth();
    const { getProductCategory_data, ProductAction } = useProduct();
    const { getCart_data } = useCart()

    const [drawer, setDrawer] = React.useState(false);
    const drawerHandler = (_) => setDrawer(!drawer);

    const current_year = document.querySelector(".current_year")
    if (current_year) {
        current_year.innerHTML = ' ' + new Date().getFullYear()
    }

    const handleRoute = (item, index) => {
        if (item?.sub_category?.length > 0) {
            let arr = document.querySelectorAll('.collapse')
            let parent = arr[index].parentElement
            let isOpened = parent.getAttribute('class')?.includes('active')
            for (let i = 0; i < arr.length; i++) {
                arr[i].parentElement.classList.remove('active')
                arr[i].parentElement.querySelector('.collapse').style.height = 0
            }
            if (!isOpened) {
                parent.classList.add('active')
                let collapse = parent.querySelector('.collapse')
                let lis = collapse.querySelectorAll('li')
                collapse.style.height = (lis[0].offsetHeight * lis.length) + 'px'
            }
        } else {
            navigate(`categories/${item.category_id}`)
            drawerHandler()
        }
    }
    const HandleSignOut = async () => {
        await dispatch(AuthAction.signOut());
    }
    return (
        <HeaderContainer>
            {
                <Media query={{ minWidth: 992 }}>
                    {(matches) =>
                        matches ? (
                            <>
                                <PrimaryNav />
                                <SecondaryNav />
                            </>
                        )
                            : (
                                <>
                                    <MobileTopNav>
                                        <div className="container-fluid">
                                            <div className="burger" onClick={drawerHandler}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                            <div className='form'>
                                                <SearchCom className="mobile" />
                                            </div>
                                            <div className="shop-cart">
                                                <Link to="/order/cart">
                                                    <FiShoppingCart size={25} />
                                                </Link>
                                                {getCart_data?.cart_item_count > 0 && <div className="cart-badge">
                                                    <TextCom color="light" size="xs">
                                                        {getCart_data?.cart_item_count}
                                                    </TextCom>
                                                </div>}
                                            </div>
                                        </div>
                                    </MobileTopNav>
                                    <MobileDrawer className={drawer && "active"}>

                                        <div className="drawer-wrapper" onClick={drawerHandler}>
                                        </div>
                                        <section className="drawer-content">
                                            <div className="content-wrapper">
                                                <div className="user">
                                                    <div className="profile" onClick={() => {
                                                        navigate(getProfile_data?.name ? "/user/profile" : "/user/signin")
                                                        drawerHandler()
                                                    }}>
                                                        <div className="icon">
                                                            <FiUser />
                                                        </div>
                                                        <TextCom size="md" color="light">{getProfile_data?.name ? getProfile_data?.name : 'Login'}</TextCom>
                                                    </div>
                                                    {
                                                        getProfile_data?.name &&
                                                        <div className='logout-icon' onClick={HandleSignOut}>
                                                            <FiLogOut />
                                                        </div>
                                                    }
                                                </div>

                                                <CollapseCom data={getProductCategory_data} />

                                                <ul className="menu-secondary">
                                                    <li onClick={() => {
                                                        navigate("/about")
                                                        drawerHandler()
                                                    }}>
                                                        <TextCom size="sm">{translate("about-us", "About us")}</TextCom>
                                                    </li>
                                                    <li onClick={() => {
                                                        navigate("/contact")
                                                        drawerHandler()
                                                    }}>
                                                        <TextCom size="sm">{translate("contact-us", "Contact us")}</TextCom>
                                                    </li>
                                                    <li onClick={() => {
                                                        navigate("/page/privacy_policy")
                                                        drawerHandler()
                                                    }}>
                                                        <TextCom size="sm">{translate("privacy-policy", "Privacy policy")}</TextCom>
                                                    </li>
                                                    <li onClick={() => {
                                                        navigate("/page/terms_and_condition")
                                                        drawerHandler()
                                                    }}>
                                                        <TextCom size="sm">{translate("terms-conditions", "Terms conditions")}</TextCom>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="footer-section">
                                                <div className="about">
                                                    <div className="logo-container">
                                                        <a href="/">
                                                            {/* <img src={getMetaData_data?.logo?.image : require('../../assets/images/demo_commerce.png')} alt="Logo" /></a> */}
                                                            <img src={require('../../assets/images/demo_commerce.png')} alt="Logo" /></a>
                                                        <ul className="social drawer">
                                                            {getContactUsPage_data &&
                                                                getContactUsPage_data[0]?.social_image_ids?.map((item) => (
                                                                    <li key={item.link}>
                                                                        <a href={item.link} target="_blank">
                                                                            <img src={item.image} />
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                    <TextCom size="sm">{getContactUsPage_data ? getContactUsPage_data[0]?.address : ''}</TextCom>
                                                </div>
                                                <div className="hotline">
                                                    <FiPhoneCall />
                                                    <div className="call-center">
                                                        <TextCom size="xs" weight="xl">Need Help?</TextCom>
                                                        <div className="context">
                                                            {getContactUsPage_data &&
                                                                getContactUsPage_data[0]?.phone.split(",").map((item) => (
                                                                    <a href={"callto:" + item} key={item}>
                                                                        {item}
                                                                    </a>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="available-app">
                                                    <TextCom weight="lg">Available on</TextCom>
                                                    <ul>
                                                        <li>
                                                            <a href='#'>
                                                                <img src={require('../../assets/images/google_play.png')} alt="Playstore" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href='#'>
                                                                <img src={require('../../assets/images/app_store.png')} alt="App Store" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <hr />
                                                <div className="copyright">
                                                    <p>
                                                        Copyright © innovixcommerce
                                                        <span className='current_year'> </span>. Powered by{" "}
                                                        <a href="https://www.innovixdigital.com/" target="_blank">
                                                            Innovix Digital
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </section>
                                    </MobileDrawer>
                                </>
                            )
                    }
                </Media>
            }

        </HeaderContainer>
    )
}

export default Header