import styled from "styled-components";
import { Text } from "../typo";
import { media, fontSize, colors, generalGap, fontWeight } from "../../attribute";

const ProfileContainer = styled.div`
  a{
    text-decoration: none;
  }
  .profile-container{
    padding-top: ${generalGap.xxxxl}px;
    @media(max-width: 991px){
      padding-top: 0px;
    }
  }
  .profile-info{
    
    .title{
      margin-bottom:30px;
    }
    .info{
        p{
          margin-bottom: ${generalGap.xs}px;
        }
        .name,.email,.phone{
          margin-bottom: ${generalGap.xxl + 5}px;
        }
    }
    .buttons{
      margin-top: ${generalGap.xxl}px;
      display: flex;
      gap: ${generalGap.xl + 2}px;
      @media(max-width: 768px){
        gap: ${generalGap.xs}px;
      }
      button{
        background: ${colors.gray2};
        color: ${colors.text};
        min-height: 40px;
        padding-inline: ${generalGap.xxl + 5}px;
        min-width: auto;
        @media(max-width: 768px){
          padding-inline: ${generalGap.xs + 5}px;
          font-size: ${fontSize.sm}px;
        }
        svg{
          margin-right: 5px;
          @media(max-width: 768px){
            margin-right: 0;
          }
        }
      }
    }
  }
`
const ProfileEditContainer = styled(ProfileContainer)`
 
  label{
    font-weight: ${fontWeight.lg};
  }
  input,textarea,select{
    border-radius: ${generalGap.xs}px;
  }
  input, select{
    height: 45px;
  }
  textarea{
    height: auto;
  }
  select{
    option{
      color: ${colors.text};
      font-weight: ${fontWeight.md};
    }
  }
  
  .info{
    svg{
      width: 25px;
      height: 25px;
      bottom: -36px;
      right: 3px;
      color: ${colors.desc}
    }
  }
  
  input{
    &::placeholder {
    opacity: 1;
    color: ${colors.text};
    /* font-weight: ${fontWeight.xs}; */
    }
  }
  .buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      min-height: 40px;
    }
  }
`


export {
  ProfileContainer,
  ProfileEditContainer
}