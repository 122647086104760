import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'

type FormContainerComProps = {
  children: any,
  validationSchema: any
}
export const FormContainerCom: React.FC<FormContainerComProps> = ({ children, validationSchema, ...props }) => {

  return (
    <Formik
      enableReinitialize
      validationSchema={() => validationSchema(yup)}
      {...props}
    >
      {formikProps =>
        <Form style={{ width: '100%' }}>
          {children(formikProps)}
        </Form>
      }
    </Formik>
  )
}