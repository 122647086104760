import httpClient from './constant/httpclient'
const queryString = require('query-string')

export * from './constant/config'
export * from './constant/routes'

export const routeFilter = params => {
  let str = queryString.stringify(params, { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' })
  return str !== '' ? `?${str}` : str
}

export default async (endpoint, ...data) => {
  let tmp = endpoint.split(':')
  return await httpClient[tmp[0]](tmp[1], ...data)
    .then(res => res && res)
    .catch(error => error)
}