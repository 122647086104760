import React from 'react'
import { FiFilter } from 'react-icons/fi'

import { CardCom, TextCom, CollapseCom, CollapsePanel, InputCom, ButtonCom } from '../../common'
import { useHandleOther, useProduct, useCart, useHandleProduct } from 'hook'
import './style.scss'

type ProductSideBarFilterCom = {}

export const ProductSideBarFilterCom: React.FC<ProductSideBarFilterCom> = ({ ...props }) => {
  const { getAllLocation_data } = useCart()
  const { getBrandList_data, getProductCategory_data } = useProduct()
  const { handleProductFilterChange, handlePriceFilter, price } = useHandleProduct()
  const { translate } = useHandleOther()

  const onHandleChange = (filter_change_obj: any) => {
    handleProductFilterChange(filter_change_obj)
  }

  const handleCategoryChange = item => {
    if (item?.item?.sub_category?.length == 0) {
      onHandleChange({ type: 'category', key: item?.item?.key })
    }
  }

  const handleNestedRenderCategory = (category: any) => (
    category?.sub_category?.length > 0 &&
    <CollapseCom accordion handleChange={handleCategoryChange}>
      {category?.sub_category?.map((sub_category: any, key: any) => (
        <CollapsePanel header={<TextCom color={getProductCategory_data?.selected_ids === sub_category?.category_id ? 'primary' : 'text'}>{sub_category?.category_name}</TextCom>} item={sub_category} key={sub_category.key}>
          {handleNestedRenderCategory(sub_category)}
        </CollapsePanel>
      ))}
    </CollapseCom>
  )

  return (
    <>
      <CardCom className='filter_card_con'>
        <div className='header_wrap'>
          <TextCom size='lg' weight='xl'>{translate('category', 'Category')}</TextCom>
          <div className='tip' />
        </div>
        {getProductCategory_data?.data?.length > 0 &&
          <CollapseCom accordion handleChange={handleCategoryChange}>
            {getProductCategory_data?.data?.map((category: any, index: any) => (
              <CollapsePanel header={<TextCom color={getProductCategory_data?.selected_ids === category?.category_id ? 'primary' : 'text'}>{category?.category_name}</TextCom>} item={category} key={category.key}>
                {handleNestedRenderCategory(category)}
              </CollapsePanel>
            ))}
          </CollapseCom>
        }
      </CardCom>

      {getBrandList_data?.data?.length > 0 && <CardCom className='filter_card_con'>
        <div className='header_wrap check_filter'>
          <TextCom size='lg' weight='xl'>{translate('filter_by_brand', 'Filter by brand')}</TextCom>
          <div className='tip' />
        </div>
        {
          getBrandList_data?.data?.map((x, i) => (
            <div key={i} className='check-item-wrap'>
              <InputCom isValidate={false} checked={x?.check || false} type='checkbox' label={x?.name} onClick={() => onHandleChange({ type: 'brand', id: x?.ID })} />
            </div>
          ))
        }
      </CardCom>}

      <CardCom className='filter_card_con'>
        <div className='header_wrap'>
          <TextCom size='lg' weight='xl'>{translate('filter_by_price', 'Filter by price')}</TextCom>
          <div className='tip' />
        </div>
        <div className='filter-item-wrap'>
          <InputCom
            className='first-input'
            placeholder='min'
            value={price?.min}
            isValidate={false}
            type='number'
            onChange={event => onHandleChange({ type: 'min_price', event })}
          />
          <InputCom
            isValidate={false}
            placeholder='max'
            value={price?.max}
            type='number'
            onChange={event => onHandleChange({ type: 'max_price', event })}
          />
          <ButtonCom
            SVGL={<FiFilter />}
            onClick={handlePriceFilter}
          />
        </div>
      </CardCom>

      {getAllLocation_data?.data?.[0]?.state_ids?.length > 0 && <CardCom className='filter_card_con'>
        <div className='header_wrap check_filter'>
          <TextCom size='lg' weight='xl'>{translate('country_of_origin', 'Country Of Origin')}</TextCom>
          <div className='tip' />
        </div>
        {
          getAllLocation_data?.data?.[0]?.state_ids?.map((x, i) => (
            <div key={i} className='check-item-wrap'>
              <InputCom isValidate={false} checked={x?.check || false} type='checkbox' label={x.name} onClick={() => onHandleChange({ type: 'location', id: x?.id })} />
            </div>
          ))
        }
      </CardCom>}
    </>
  )
}