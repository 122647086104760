import React from 'react'

import { QtyButtonCom } from '../../common'
import { useHandleQty } from 'hook'

type QtyButtonModProps = {}
export const QtyButtonMod: React.FC<QtyButtonModProps> = ({ type = 'productbyid', item, w_index = 0, c_index = 0, ...props }) => {
  const [handleQty, handleQtyFun] = useHandleQty({
    type,
    item,
    w_index,
    c_index
  })

  return (
    <QtyButtonCom
      qty={handleQty}
      setQty={qty => handleQtyFun(+qty)}
      {...props}
    />
  )
}