import { StyledText } from 'theme'

export const Text = (props) => {
  return <StyledText {...props} />
}

type TextComProps = {

}

export const TextCom: React.FC<TextComProps> = props => {
  return <StyledText {...props} />
}