import AppRoute from './../router/router'
import { GlobalStyle, Main } from 'theme'
import Header from './header'
import Footer from './footer'

const AppLayout = () => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Main>
        <AppRoute />
      </Main>
      <Footer />
    </>
  )
}

export default AppLayout