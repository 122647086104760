import React, { useState, useEffect } from 'react'

import { RSSliderCom } from '../../common'
import './style.scss'

type GallerySliderProps = {

}

let slider1 = []
export const ProductGallerySlider: React.FC<GallerySliderProps> = props => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)

  useEffect(() => {
    setNav1(slider1)
  }, [])

  let body_rs_props = {
    dots: false,
    arrows: false,
    adaptiveHeight: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  let foot_rs_props = {
    swipeToSlide: true,
    focusOnSelect: true,
    adaptiveHeight: true,
    infinite: false,
    slidesToShow: props?.data?.length >= 5 ? 5 : props?.data?.length,
    slidesToScroll: 1
  }

  return (
    <>
      <RSSliderCom
        asNavFor={nav2}
        innerRef={(slider) => (slider1 = slider)}
        rsProps={body_rs_props}
        type='zoom'
        {...props}
      />
      {props?.data?.length > 1 && <RSSliderCom
        className='slide-nav'
        asNavFor={nav1}
        innerRef={setNav2}
        rsProps={foot_rs_props}
        {...props}
      />}
    </>
  )
}