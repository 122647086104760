import React from "react";

import { StyledTableContainer } from "theme";
import { TableHeadCom } from "./TableHead";
import { TableBodyCom } from "./TableBody";
import { TableFootCom } from "./TableFoot";

type TableComProps = {
  pagination: any;
};

export const TableCom: React.FC<TableComProps> = (props) => {
  return (
    <StyledTableContainer {...props.StyledTableContainer}>
      <TableHeadCom {...props} />
      <TableBodyCom {...props} />
      {props?.pagination && <TableFootCom {...props} />}
    </StyledTableContainer>
  );
};
