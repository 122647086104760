import React from 'react'
import styled, { css } from 'styled-components'
import { Text, media } from 'theme'

const Placeholder = styled(Text)`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
  border: 10px solid red;
  ${({ banner_sm_height }) => banner_sm_height && css`
    height: ${props => props.banner_sm_height}px;
  `}
  @media screen and (min-width: ${media.md}px) {
    ${({ banner_sm_height }) => banner_sm_height && css`
      height: ${props => props.height}px;
    `}
  }
  &:before {
    content: "${props => props.text}";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const CustomPlaceholder = props => {
  return (
    <Placeholder color="label" cu_size="30" {...props} />
  )
}


// const Customplaceholder = props => {
//   console.log('props', props.height)
//   const node = "ImgFake-" + Math.floor(Math.random() * 9999)

//   useEffect(() => {
//     let parent = document.querySelector(`.${node}`)

//       setTimeout(() => {
//         if (props.unresponsive) {
//           parent.style.width = props?.width + 'px'
//           parent.style.height = props?.height + 'px'
//           parent.style.opacity = 1
//         } else {
//           parent.style.height = ((parent.offsetWidth / props?.width) * props?.height) + 'px'
//         }
//       }, 1000)
//     // window.addEventListener('resize', function() {window.location.reload()})
//   }, [node, props])

//   return <div className={node} style={{ opacity: 0 }} />
// }

// export default CustomPlaceholder
