import React, { useEffect } from "react";
import { useProduct, useOther } from "hook";
import { MainFooter } from "theme";
import Icon from "assets/icons/demo";
import { TextCom } from "components";
import { getUiTranslate, en2mm_num } from "utils";
import { useCart, useHandleOther } from "../../hook";
export type FooterProps = {};
const Footer: React.FC<FooterProps> = (props) => {
  const { dispatch, ProductAction, getProductCategory_data } = useProduct();

  const {
    dictionary_data,
    langStore,
    getMetaData_data,
    OtherAction,
    getContactUsPage_data,
  } = useOther();
  const { CartAction, getCart_data } = useCart();
  const { translate } = useHandleOther();
  const langCode = langStore?.code;

  useEffect(() => {
    dispatch(OtherAction.getMetaData());
  }, [langCode]);

  return (
    <MainFooter>
      {getContactUsPage_data && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <TextCom color="#DDDDDD" size="lg" className="footer-header">
                  {translate("contact_us", "Contact Us")}
                </TextCom>

                <div className="footer-flex">
                  <img src={Icon.Location} alt="" />
                  <TextCom color="#707070" size="md">
                    {getContactUsPage_data[0]?.address}
                  </TextCom>
                </div>

                <div className="footer-flex">
                  <img src={Icon.Mail} alt="" />
                  <TextCom color="#707070" size="md">
                    {getContactUsPage_data
                      ? getContactUsPage_data[0]?.email
                      : ""}
                  </TextCom>
                </div>
                <div className="footer-flex phone">
                  <img src={Icon.Telephone} alt="" />
                  <div>
                    {getContactUsPage_data &&
                      getContactUsPage_data[0]?.phone.split(",").map((ph) => {
                        return (
                          <TextCom
                            as="a"
                            color="#707070"
                            href={"tel:" + ph}
                            key={ph}
                          >
                            {en2mm_num(ph, langCode)}
                          </TextCom>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="col-sm-6 footer-flex ">
                <div className="footer-information">
                  <TextCom color="#DDDDDD" size="lg" className="footer-header">
                    {translate("informations", "Information")}
                  </TextCom>
                  <TextCom
                        className="footer-flex"
                        as="a"
                        color="#707070"
                        size="md"
                        href={`/aboutus`}
                      >
                        {translate('about-us','About Us')}
                      </TextCom>
                      <TextCom
                        className="footer-flex"
                        as="a"
                        color="#707070"
                        size="md"
                        href={`/contactus`}
                      >
                        {translate('contact-us','Contact Us')}
                      </TextCom>
                      <TextCom
                        className="footer-flex"
                        as="a"
                        color="#707070"
                        size="md"
                        href={`/page/privacy_policy`}
                      >
                        {translate('privacy-policy','Privacy Policy')}
                      </TextCom>
                      <TextCom
                        className="footer-flex"
                        as="a"
                        color="#707070"
                        size="md"
                        href={`/page/terms_and_condition`}
                        
                      >
                        
                        {translate('term-and-condition','Terms And Conditions')}
                      </TextCom>
                </div>
                <div className="payment-type">
                  <TextCom color="#DDDDDD" size="lg" className="footer-header">
                    {translate("payment-types", "Payment Type")}
                  </TextCom>
                  <img src={Icon.Cbpay} alt="" />
                  <img src={Icon.Kbzpay} alt="" />
                  <img src={Icon.Wavepay} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container copyright-block">
              <TextCom color="desc">
                Copyright © innovixcommerce 2021. Powered by Innovix Digital.
              </TextCom>
              <ul className="social-image">
                {getContactUsPage_data?.[0]?.social_image_ids?.map((item) => {
                  return (
                    <li>
                      <img className="img-fluid" src={item.image} alt="" />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </>
      )}
    </MainFooter>
  );
};

export default Footer;