import { lazy } from "react";

const profile = lazy(() => import('./profile'))
const profileEdit = lazy(() => import('./edit'))
const orderlist = lazy(() => import('./orderlist'))
const orderdetail = lazy(() => import('./orderdetail'))
const wishlist = lazy(() => import('./wishlist'))
const accountlist = lazy(() => import('./accountlist'))
const review = lazy(() => import('./review'))

const Account = {
  profile,
  profileEdit,
  orderlist,
  orderdetail,
  wishlist,
  accountlist,
  review
}
export default Account