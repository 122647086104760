const hasPrefix = (action: AnyAction, prefix: string) => action.type.startsWith(prefix)
const isPending = (action: AnyAction) => action.type.endsWith('/pending')
const isFulfilled = (action: AnyAction) => action.type.endsWith('/fulfilled')
const isRejected = (action: AnyAction) => action.type.endsWith('/rejected')

export const isPendingAction = (prefix: string) => (action: AnyAction): action is AnyAction => { // Note: this cast to AnyAction could also be `any` or whatever fits your case best
  return hasPrefix(action, prefix) && isPending(action)
}

export const isRejectedAction = (prefix: string) => (action: AnyAction): action is AnyAction => { // Note: this cast to AnyAction could also be `any` or whatever fits your case best - like if you had standardized errors and used `rejectWithValue`
  return hasPrefix(action, prefix) && isRejected(action)
}

export const isFulfilledAction = (prefix: string) => (action: AnyAction): action is AnyAction => {
  return hasPrefix(action, prefix) && isFulfilled(action)
}

export const ToDoSuccess = (type, data) => ({ type, data })
export const ToDoRequest = type => ({ type })
export const ToDoError = (type, error) => ({ type, error })