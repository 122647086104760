import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CardCom, TextCom, RatingCom } from '../../common'
import { moneyFormat } from 'utils'
import { useHandleOther } from 'hook'
import './style.scss'

type ProductCardComProps = {

}
export const ProductcardCom: React.FC<ProductCardComProps> = (props) => {
  const { data, route } = props
  const { translate } = useHandleOther()
  const { langStore } = useSelector((state) => state.other)
  const navigate = useNavigate()

  const langCode = langStore?.code
  const handleRouteChange = (route) => {
    navigate(route)
  }

  return (
    <CardCom className='product-card-com' onClick={() => handleRouteChange(route)}>
      <div className='img_wrapper'>
        <img src={data?.product_image || require('assets/images/demo/empty_2.jpg')} alt='' />
      </div>
      <div className='content'>
        <TextCom weight='lg' textAlign='center' color='desc' className='name_txt'>{data?.name}</TextCom>
        <div className='price_con'>
          <TextCom textAlign='center' weight='xxl' className='price_txt'>
            {moneyFormat(data?.list_price, langCode)} {translate('ks', 'Ks')}
          </TextCom>
        </div>
        <div className='star_con'>
          <RatingCom
            disabled
            allowHalf
            defaultValue={2.5}
            allowClear={true}
            size={20}
          />
        </div>
      </div>
    </CardCom>
  )
}