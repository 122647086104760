import axios from 'axios'

import { configKey } from './config'
import { authStore } from '../../service'

const client = axios.create()

client.interceptors.request.use(async (config) => {
   config.baseURL = configKey.apiUrl

   let res = await authStore.getAuth()

   config.headers["access-token"] = res?.access_token || "";

   return config
}, (error) => {
   return Promise.reject(error)
})

client.interceptors.response.use(async (response) => {
   if (!response.data) {
      return Promise.reject(response)
   }
   return response

}, async (error) => {
   return Promise.reject(error)
})

export default client