import styled, { css } from "styled-components";
import { colors, fontSize, fontWeight, media } from ".";
import Icon from "assets/icons/demo";
const Main = styled.main`
  min-height: 70vh;
  @media(max-width: 991px){
    margin-top: 57px;
  }
`

const Section = styled.section`
  padding-top: 20px;
  padding-bottom: 10px;

  @media screen and (min-width: ${media.md}px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
`;

const UlStyle = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  li {
    position: relative;
    padding: 0;
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    &:before {
      content: "";
      background: url(${Icon.TickImage}) no-repeat center / cover;
      width: 12px;
      height: 12px;
      display: inline-block;
      flex: none;
      margin-right: 10px;
    }
  }

  ${({ lightIcon }) =>
    lightIcon &&
    css`
      li {
        &:before {
          background-image: url(${Icon.TickImage});
        }
      }
    `}
`;

const Tab = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: ${colors.light};

    li {
      padding: 10px 20px;
      color: ${colors.label};
      font-weight: ${fontWeight.md};
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      font-size: ${fontSize.sm}px;
      user-select: none;

      @media screen and (min-width: ${media.md}px) {
        padding: 20px 30px;
        width: auto;
        font-size: ${fontSize.md}px;
      }

      &.active,
      &:hover {
        color: ${colors.light};
        background-color: ${colors.secondary};
      }
    }
  }
`;
const Content = styled.div`
  .tabcontent {
    display: none;
    &.active {
      display: block;
    }
  }
`;
const ProductInfoTab = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 992px) {
      flex-wrap: initial;
    }

    li {
      padding: 8px 8px;
      color: ${colors.label};
      font-weight: ${fontWeight.md};
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      user-select: none;
      background-color: ${colors.gray};
      width: 50%;
      margin-right: 0;
      border-radius: 0;
      border: 2px solid #ffff;

      @media screen and (min-width: 992px) {
        border-radius: 10px 10px 0px 0px;
        border: 0;
        width: auto;
        margin-right: 3px;
      }

      &.active,
      &:hover {
        p {
          color: ${colors.light};
        }
        background-color: ${colors.primary};
      }
    }
  }
`;

const Image = styled.img`
  width: 100%;
`;

const RTEContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 800;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;

    li {
      padding-left: 20px;
      position: relative;
      &:before {
        content: "";
        background: url(${Icon.TickImage}) no-repeat center / cover;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }
  }
`;

const CopyrightStyled = styled.p`
  color: ${colors.label};
  font-size: ${fontSize.sm}px;
  font-weight: ${fontWeight.md};
  margin-bottom: 0;
`;

const Copyright = ({ data }) => (
  <CopyrightStyled>
    Copyright © {new Date().getFullYear()} {data} . All right reserved. Powered
    by
    <a
      href="https://www.innovixdigital.com/"
      style={{ marginLeft: 5 }}
      target="_blank"
      rel="noreferrer"
    >
      Innovix Digital.
    </a>
  </CopyrightStyled>
);

export {
  Main,
  Section,
  UlStyle,
  Tab,
  ProductInfoTab,
  Image,
  RTEContent,
  Copyright,
  Content,
};
