import { createSlice } from '@reduxjs/toolkit'
import * as theme from '../../../theme/attribute'

let darkTheme = { ...theme, color: theme.darkcolor }
let lightTheme = { ...theme, color: theme.lightcolor }

let loc = localStorage.getItem('store_theme')

const initialState = {
  baseTheme: loc === 'dark' ? darkTheme : loc === 'light' ? lightTheme : lightTheme,
  createToast_data: {
    toastModalVisible: false,
    modalWidth: 350
  }
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.createToast_data = action.payload
    }
  }
})

export default {
  themeSlice: themeSlice.reducer,
  setToast: themeSlice.actions.setToast
}
