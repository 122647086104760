import { useAuth, useProduct } from '../../common'

type Props = {}

export const useHandleWishlist = (props: Props) => {
  const { getProductById_data, getWishlist_data, ProductAction, dispatch } = useProduct()
  const { getProfile_data } = useAuth()
  let exist = getProductById_data?.check_wishlist?.exist

  const getWishlist = data => {
    dispatch(ProductAction.getWishlist(data))
  }

  const handleIsExistWishlist = async data => {
    if (getProfile_data) {
      let pos_data = {
        item: getProductById_data,
        product_id: data?.product_id,
        variant_id: getProductById_data?.attributes?.selected_variant_ids,
        isExistCheck: false
      }
      let res = await dispatch(ProductAction.getExistsWishlist(pos_data))
      return res
    } else {
      // open modal
      return ({
        required_login: true
      })
    }
  }

  const handleWislistItemDelete = async item => {
    let pos_data = { wishlist_id: item.id }
    dispatch(ProductAction.removeWishlist(pos_data))
  }

  return {
    exist,
    getWishlist_data,
    getWishlist,
    handleIsExistWishlist,
    handleWislistItemDelete
  }
}