import { lazy } from "react";

const signin = lazy(() => import ('./signin'))
const signup = lazy(() => import ('./signup'))
const otp = lazy(() => import ('./otp'))
const changepwd = lazy(() => import ('./changepwd'))
const forgotpwd = lazy(() => import ('./forgotpwd'))
const success = lazy(()=> import('./success'))
const Auth = {
  signin,
  signup,
  otp,
  changepwd,
  forgotpwd,
  success
}
export default Auth