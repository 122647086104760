import styled from "styled-components";
import { rgba } from "polished";
import { CustomPlaceholder } from "components";
import {
  media,
  fontSize,
  fontWeight,
  Section,
  colors,
  General,
} from "../../attribute";
import Icon from "assets/icons/demo";

const defaultSettings = {
  homeBanner: {
    width: 1200,
    height: 400,
    banner_sm_height: 200,
    backgroundColor: colors.light,
    text: "1200 x 400",
    opacity: 0,
  },
  pageBanner: {
    width: 1280,
    height: 300,
    backgroundColor: colors.light,
    text: "1200 x 250",
    opacity: 0,
  },
};

const PageBannerSection = styled(Section)`
  padding: 0;
  overflow-x: hidden;
`;

// home banner
const HomeBannerSection = styled.div`
  img {
    width: 100%;
  }
  .slick-slider {
    /* z-index: 1;
    &:hover {
      opacity: 1;
      visibility: visible;
      .slick-arrow {
        opacity: 1;
        visibility: visible;
      }
    } */
    .slick-arrow {
      width: 25px;
      height: 30px;
      background-color: ${colors.gray};
      z-index: 1;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease;
      /* @media screen and (min-width: 768px) {
        opacity: 0;
      } */
      &:before {
        content: "";
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      &.slick-prev {
        left: 0;
        &:before {
          background: url(${Icon.Arrow}) no-repeat center / cover;
          transform: rotate(180deg);
        }
      }
      &.slick-arrow {
        right: 0;
        &:before {
          background: url(${Icon.Arrow}) no-repeat center / cover;
        }
      }
    }
  }
  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    bottom: 5px;
    li {
      width: 140px;
      height: 80px;
      background-color: ${rgba(colors.gray, 0.6)};
      margin: 0;
      border-right: 2px solid ${colors.gray};
      &:last-child {
        border-right: 0;
      }
      button {
        font-size: ${fontSize.md}px;
        color: ${colors.light};
        width: 100%;
        height: 100%;
        line-height: 20px;
        &:before {
          content: "";
          font-family: ${General.fontFamily};
          color: ${colors.light};
          font-size: ${fontSize.md}px;
          opacity: 1;
          position: initial;
        }
      }
      &.slick-active {
        background-color: ${colors.primary};
        button {
          &:before {
            color: ${colors.light};
          }
          &:after {
            content: "";
            width: 0;
            height: 0;
            display: inline-block;
            border: 10px solid transparent;
            border-bottom-color: ${colors.primary};
            border-radius: 0.05rem;
            margin: 0 auto;
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
`;

const TitleWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  h4 {
    padding-bottom: 0;
  }
`;

const BannerContainer = styled.div`
  height: 100%;
`;

const BannerRow = styled.div`
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const BannerCol = styled.div`
  padding-left: 15px;
  @media screen and (min-width: 768px) {
    padding-left: 6em;
  }
  p {
    width: 100%;
    @media screen and (min-width: ${media.md}px) {
      width: 60%;
    }
  }
`;

const SlideData = styled.div`
  position: relative;
  background: url("${(props) => props.data}") no-repeat center / cover;
  img {
    opacity: 0;
  }
  .banner {
    position: relative;
  }
  .content-flex {
    width: 100%;
    height: 100%;
    padding: 10px;
    position: absolute;
    top: 37px;
    left: 0;
    @media screen and (min-width: ${media.lg}px) {
      padding-top: 35px;
    }
    @media screen and (min-width: ${media.xl}px) {
      padding: 65px;
    }
    .content {
      width: 95%;
      padding: 10px;
      padding-bottom: 2px;
      display: inline-block;
      background-color: ${rgba(colors.gray, 0.6)};
      position: absolute;
      bottom: 9px;
      left: 8px;
      @media screen and (min-width: ${media.sm}px) {
        position: initial;
      }
      @media screen and (min-width: ${media.md}px) {
        padding: 20px;
        width: 485px;
      }
      h4 {
        margin-bottom: 15px;
        @media screen and (max-width: ${media.md}px) {
          font-size: 16px;
          line-height: 10px;
          margin-bottom: 8px;
        }
      }
      ul {
        margin-bottom: 10px;
        @media screen and (max-width: ${media.md}px) {
          margin-bottom: 5px;
        }
        li {
          color: ${colors.light};
          font-weight: ${fontWeight.sm};
          margin-bottom: 5px;
          @media screen and (max-width: ${media.md}px) {
            font-size: ${fontSize.xs}px;
            margin-bottom: 0;
          }
        }
      }
      a {
        text-decoration: none;
      }
      button {
        margin: 0;
        color: ${colors.light};
        @media screen and (max-width: ${media.md}px) {
          min-height: 25px;
          font-size: ${fontSize.xs}px;
        }
        svg {
          color: ${colors.light};
        }
      }
      &.transparent {
        background-color: transparent;
      }
    }
  }
`;

const CooperateBanner = styled(HomeBannerSection)`
  .content-flex {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content {
      padding: 0;
      padding-left: 15px;
    }
  }
`;

const SlideBackgroundImage = ({ data, ...props }) => {
  return (
    <SlideData data={data}>
      <div className="container banner">
        <div className="row">
          <div className="col-12">
            <CustomPlaceholder {...defaultSettings.homeBanner} />
            {props.children}
          </div>
        </div>
      </div>
    </SlideData>
  );
};

export {
  HomeBannerSection,
  CooperateBanner,
  defaultSettings,
  PageBannerSection,
  TitleWrap,
  BannerContainer,
  BannerRow,
  BannerCol,
  SlideBackgroundImage,
};
