import styled from "styled-components";

const StyledSuccessCon = styled.div`
  position: relative;
  height: 560px;
  .success-con {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .img-animate {
      position: relative;
      .static-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 222;
      }
    }
    h1 {
      @media (min-width: 991px) {
        margin-bottom: 25px;
      }
    }
    .paragraph {
      margin-bottom: 10px;
    }
    img {
      margin: auto;
    }
    button {
      width: 100%;
    }
    a {
      text-decoration: none;
    }
    .animate {
      animation: zoom 2s normal forwards ease-in-out;
    }
    @keyframes zoom {
      from {
        transform: scale(0);
      }
      90% {
        opacity: 0.5;
      }
      to {
        transform: scale(1.8);
        opacity: 0;
      }
    }
    @keyframes fade {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
`;
export { StyledSuccessCon };
