export const colors = {
  primary: "#1585B4",
  secondary: "#272727",
  success: "#B5D335",
  warning: "##FFC107",
  danger: "#FD583E",
  text: "#333333",
  textsecondary: '#505050',
  link: "#30ACFF",
  label: "#333333",
  desc: "#707070",
  light: "#fff",
  err_status: "#B52626",
  gray: "#DDDDDD",
  gray2: "#EEEEEE",
  gray3: "#F5F5F5",
  gray4: '#f9f9f9',
  secondaryBgColor: "#F8F8F8",
  borderColor: "#EDEDED",
  textColor: "#021C19",
  iconColor: "#021C19",
  dark: "#000000",
  activeTintColor: "#F7A928",
  transparent: "transparent",
  shadow: "#00000024",
  primaryBackground: "#B9D4DF",
};

export const darkcolor = {
  mode: "dark",
  typo: {
    ...colors
  },

  statusBar: {
    bgColor: "#8b8c8c",
  },

  header: {
    ...colors,
    background: 'red',
    bgColor: '#000',
    primary: '#1585B4',
    secondaryBgColor: '#F8F8F8',
    borderColor: '#CECECE',
    textColor: '#FFFFFF',
    iconColor: '#FFFFFF'
  },

  refresh: {
    primary: "#1585B4",
  },

  container: {
    ...colors,
    bgColor: "#000000",
    secondaryBgColor: "#000",
  },

  footer: {
    primary: "#1585B4",
    secondary: "#CCC",
    bgColor: "#000",
    borderColor: "#f0f0f0",
    dark: "#000000",
    gray: "#707070",
  },

  drawer: {
    activeTintColor: "#1585B4",
    bgColor: "#F5F5F5",
    borderColor: "#EDEDED",
  },

  button: {
    ...colors,
    fbBgColor: '#3B5998',
  },

  form: {
    ...colors,
    primary: '#1585B4',
    white: '#ffff',
    inputBgColor: '#fff',
    borderColor: '#EEEEEE',
    radio_container: '#1585B4',
    radio_selected: '#1585B4',
    check_container: '#1585B4',
    check_selected: '#fff',
    shadow: '#00000026'
  },

  card: {
    ...colors,
    primary: "#1585B4",
    dark: "#000000",
    bgColor: "#242526",
    borderColor: "#3A3B3C",
    secondaryBgColor: "#EEE",
  },

  list: {
    primary: "#1585B4",
    bgColor: "#ffffff",
    borderColor: "#f0f0f0",
    dark: "#000000",
    shadow: "#00000026",
  },

  modal: {
    ...colors,
  },

  loading: {
    primary: "#1585B4",
  },

  icon: {
    primary: "#fff",
    secondary: "",
    tertiary: "",
    success: "#1585B4",
    warning: "#FFDA44",
    danger: "#FD583E",
    transparent: "transparent",
    label: "#333333",
    desc: "#999999",
    dark: "#ffffff",

    white: "#ffffff",
    dark_light: "#333333",
    dark_gray: "#8b8c8c",
    gray: "#707070",
    gray_light: "#CCC",
  },

  table: {
    ...colors,
  },

  breadcrump: {
    ...colors,
  },
};

export const lightcolor = {
  mode: "light",
  typo: {
    ...colors
  },

  statusBar: {
    bgColor: "#fff",
  },

  header: {
    ...colors,
    background: 'red',
    bgColor: '#FFFF',
    primary: '#1585B4',
    secondaryBgColor: '#F8F8F8',
    borderColor: '#F7A928',
    textColor: '#FFFFFF',
    iconColor: '#FFFFFF'
  },

  refresh: {
    primary: "#F7A928",
  },

  container: {
    ...colors,
    bgColor: "#F5F5F5",
    secondaryBgColor: "#F8F8F8",
  },

  footer: {
    primary: "#1585B4",
    secondary: "#222",
    bgColor: "#ffffff",
    borderColor: "#f0f0f0",
    dark: "#000000",
    gray: "#707070",
  },

  drawer: {
    activeTintColor: "#1585B4",
    bgColor: "#F5F5F5",
    borderColor: "#EDEDED",
  },

  button: {
    ...colors,
    fbBgColor: '#3B5998',
  },

  form: {
    ...colors,
    primary: '#1585B4',
    white: '#ffff',
    inputBgColor: '#fff',
    borderColor: '#EEEEEE',
    radio_container: '#1585B4',
    radio_selected: '#1585B4',
    check_container: '#1585B4',
    check_selected: '#fff',
    shadow: '#00000026'
  },

  card: {
    ...colors,
    primary: "#1585B4",
    bgColor: "#ffffff",
    borderColor: "#f0f0f0",
    dark: "#000000",
    shadow: "#00000026",
    secondaryBgColor: "#EEE",
  },

  list: {
    primary: "#1585B4",
    bgColor: "#ffffff",
    borderColor: "#f0f0f0",
    dark: "#000000",
  },

  modal: {
    ...colors,
  },

  loading: {
    primary: "#1585B4",
  },

  icon: {
    primary: "#000",
    secondary: "",
    tertiary: "",
    success: "#1585B4",
    warning: "#FFDA44",
    danger: "#FD583E",
    transparent: "transparent",
    label: "#333333",
    desc: "#999999",
    dark: "#000000",

    white: "#ffffff",
    dark_light: "#333333",
    dark_gray: "#8b8c8c",
    gray: "#707070",
    gray_light: "#CCC",
  },
  table: {
    ...colors,
  },
  breadcrump: {
    ...colors,
  },
};
