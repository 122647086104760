import styled, { css } from 'styled-components'
import { colors } from '../../attribute';

export const AlertMessage = styled.div`
  border-radius: .3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: .5rem 1rem;
  margin-bottom: 10px;
  transition: all .3s ease;

  ${({ alert_type }) => alert_type === 'success' && css`
    color: ${colors.success};
    background-color: ${colors.success};
    border: 1px solid ${colors.success};
  `}

  ${({ alert_type }) => alert_type === 'warning' && css`
    color: ${colors.warning};
    background-color: ${colors.warning};
    border: 1px solid ${colors.warning};
  `}

  ${({ alert_type }) => alert_type === 'danger' && css`
    color: ${colors.danger};
    background-color: ${colors.danger};
    border: 1px solid ${colors.danger};
  `}

  ${({ alert_type }) => alert_type === 'info' && css`
    color: ${colors.link};
    background-color: ${colors.link};
    border: 1px solid ${colors.link};
  `}

  svg {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    &.close {
      cursor:pointer;
    }
  }
`