import React from 'react'

import { StyledButton, StyledBtnSvgL, StyledBtnSvgR } from 'theme'
import { TextCom } from '../typo'

type ButtonComProps = {

}

export const ButtonCom: React.FC<ButtonComProps> = props => {
  const { children, SVGL, SVGR, text, onClick } = props

  return (
    <StyledButton onClick={onClick} {...props}>
      {
        children ?
          children
          :
          <>
            {SVGL && <StyledBtnSvgL {...props}>{SVGL}</StyledBtnSvgL>}
            {text && <TextCom {...props}>{text}</TextCom>}
            {SVGR && <StyledBtnSvgR {...props}>{SVGR}</StyledBtnSvgR>}
          </>
      }
    </StyledButton>
  )
}

ButtonCom.defaultProps = {
  bgColor: 'primary',
  color: 'text',
  type: 'full'
}