import React from 'react'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'

import { StyledAddToCartBtnWrap, StyledAddToCartBtnLeft, StyledAddToCartBtnCenter, StyledAddToCartBtnRight } from 'theme'
import { InputCom } from '../form'

type QtyButtonComProps = {
  qty: number,
  setQty: any,
  rightBtnRender: any,
  leftBtnRender: any,
  rightBtnStyle: any,
  leftBtnStyle: any
}

export const QtyButtonCom: React.FC<QtyButtonComProps> = props => {
  const { qty, setQty, rightBtnRender, leftBtnRender, rightBtnStyle, leftBtnStyle } = props

  const handleQty = key => {
    if (key === 'add') {
      let num = parseInt(qty)
      num += 1
      setQty(num)
    } else {
      if (parseInt(qty) > 1) {
        let num = parseInt(qty)
        num -= 1
        setQty(num)
      }
    }
  }

  return (
    <StyledAddToCartBtnWrap {...props}>
      <StyledAddToCartBtnLeft {...props}>
        {
          rightBtnRender ?
            <div className={leftBtnStyle || 'left_btn_wrap'} onClick={() => handleQty('sub')} {...props}>
              {rightBtnRender}
            </div>
            :
            <div className={leftBtnStyle || 'left_btn_wrap'} onClick={() => handleQty('sub')} {...props}>
              <AiOutlineMinus />
            </div>
        }
      </StyledAddToCartBtnLeft>

      <StyledAddToCartBtnCenter {...props}>
        <InputCom
          className='qty-input'
          value={qty}
          onChange={e => setQty(e.target.value)}
          isValidate={false}
          {...props}
        />
      </StyledAddToCartBtnCenter>

      <StyledAddToCartBtnRight {...props}>
        {
          leftBtnRender ?
            <div className={rightBtnStyle || 'right_btn_wrap'} onClick={() => handleQty('add')} {...props}>
              {leftBtnRender}
            </div>
            :
            <div className={rightBtnStyle || 'right_btn_wrap'} onClick={() => handleQty('add')} {...props}>
              <AiOutlinePlus />
            </div>
        }
      </StyledAddToCartBtnRight>
    </StyledAddToCartBtnWrap>
  )
}

QtyButtonCom.defaultProps = {
  bgColor: 'primary',
  color: 'white',
  type: 'full',
  inputWidth: 60,
  qty: 1
}