import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { GrEdit } from 'react-icons/gr'
import { RiDeleteBinLine } from 'react-icons/ri'

import { CardCom, TextCom } from '../../common'
import { useHandleOther } from 'hook'
import './style.scss'

type AddressCardComProps = {
  item: any,
  itemClick: any
}
export const AddressCardCom: React.FC<AddressCardComProps> = ({ item, itemClick, onHandleSelectShipping, ...props }) => {
  const { translate } = useHandleOther()

  return (
    item ?
      <CardCom onClick={() => onHandleSelectShipping(item)} borderColor={item?.check ? 'primary' : 'borderColor'} {...props}>
        <>
          <div className='address_icon_head_con'>
            <div className='address_icon_right_wrap'>
              <GrEdit onClick={() => itemClick(item, 'edit_shipping')} />
              <RiDeleteBinLine onClick={() => itemClick(item, 'delete')} />
            </div>
          </div>
          <TextCom>{item?.name}, {item?.mobile}</TextCom>
          <TextCom>{item?.address1}</TextCom>
        </>
      </CardCom>
      :
      <CardCom onClick={() => itemClick(item, 'create_shipping')} className='empty_address_item' {...props}>
        <AiOutlinePlus />
        <TextCom textAlign='center'>{translate('add_address', 'Add Address')}</TextCom>
      </CardCom>
  )
}