import styled, { css } from "styled-components";
import {
  fontSize,
  fontWeight,
  General,
  InputGeneral,
  colors,
  generalGap,
} from "../../attribute";

const StyledSignin = styled.div`
  .border {
    border: 1px solid #eeeeee;
    padding: 30px;
    margin-top: 80px;
  }
  .sign-field {
    margin-bottom: 12px;
  }
  .red-star {
    color: red;
  }
  h3 {
    text-align: center;
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
  input {
    border: 1px solid #eeeeee;
    padding: 10px;
  }
  .first-link-group {
    button {
      min-width: 0px;
      padding: 0;
      text-align: left;
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
  .input-feedback {
    color: red;
  }
  .second-link-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
    p {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #eeeeee;
      line-height: 0.1em;
      margin: 10px 0 20px;
    }

    p span {
      background: #fff;
      padding: 0 10px;
    }
  }
  .verify-code {
    border: 1px solid red;
  }
`;

export { StyledSignin };
