import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Media from "react-media";

import { FiShoppingCart, FiChevronDown, FiChevronRight } from "react-icons/fi";
import { MdOutlineHighlightOff } from "react-icons/md";
import { TextCom, ButtonCom } from "components";
import { SecondaryNavContainer } from "theme";

import { useOther, useProduct, useCart, useHandleOther } from "../../hook";
import SearchCom from "./components/SearchCom";
import { moneyFormat } from "../../utils";


const queryString = require("query-string");

const SecondaryNav = () => {
  const { getMetaData_data, langStore, OtherAction } = useOther();
  const { getProductCategory_data, ProductAction } = useProduct();
  const { getCart_data, dispatch, CartAction } = useCart();
  const { translate } = useHandleOther();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const langCode = langStore?.code;

  useEffect(() => {
    dispatch(OtherAction.getContactUsPage({ lang: langCode }));
    // dispatch(ProductAction.getProductCategory({ lang: langCode }));
  }, [langCode]);


  //mega menu
  const [activeCategory, setActiveCategory] = React.useState([]);

  // React.useEffect(
  //   (_) => {
  //     let li = document.querySelectorAll(".mega-menu .mega-menu-left li");
  //     let megaMenu = document.querySelector(".mega-menu");
  //     for (let i = 0; i < li.length; i++) {
  //       li[i].addEventListener("mouseenter", (_) => {
  //         for (let i = 0; i < li.length; i++) {
  //           li[i].classList.remove("active");
  //         }
  //         li[i].classList.add("active");
  //         megaMenu?.classList.add("right-active")
  //         setActiveCategory(getProductCategory_data?.data[i]?.sub_category);
  //       });
  //       // setActiveCategory(getProductCategory_data?.data[0]?.sub_category);
  //     }
  //   },
  //   [getProductCategory_data]
  // );

  let product_order_list = getCart_data?.order_lines?.filter(x => x?.product_type === 'product')
  let order_list = product_order_list?.length > 3 ? product_order_list.slice(0, 3) : product_order_list

  return (
    <SecondaryNavContainer>
      <div className="container secondary-nav">
        <div className="logo-container">
          <div className="logo">
            <Link to="/">
              <img
                src={
                  getMetaData_data?.logo?.image ||
                  require("../../assets/icons/innovix.png")
                }
              />
            </Link>
          </div>

          {/* Mega menu */}
          {/* <div className="mega-menu-container">
            <div className="menu">
              <div className="burger">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <Link to="../">
                <TextCom
                  color="light"
                  size="sm"
                  weight="medium"
                >Categories</TextCom>
              </Link>
              <FiChevronDown color="white" />
            </div>
            <div className="mega-menu" >
              <ul className="mega-menu-left">
                {getProductCategory_data?.data?.map((item, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={
                          item?.sub_category.length > 0
                            ? `/categories/${item.category_id}`
                            : `/products/${item.category_id}`
                        }
                      >
                        <TextCom weight="medium">{item.category_name}</TextCom>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              {activeCategory?.length >= 0 && (
                <ul className="mega-menu-right">
                  {activeCategory !== null &&
                    activeCategory.map((item, i) => (
                      <li key={i} className="products">
                        <Link to={`/products/${item.category_id}`}>
                          <TextCom weight="medium">{item.category_name}</TextCom>
                        </Link>
                        <ul>
                            <li><TextCom color="desc" size="sm" weight="medium" >one</TextCom></li>
                            <li><TextCom color="desc" size="sm" weight="medium" >two</TextCom></li>
                            <li><TextCom color="desc" size="sm" weight="medium" >three</TextCom></li>
                        </ul>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div> */}
        </div>
        <div className="navigation">
          <ul>
            <li>
              <Link to="/">
                <TextCom size="sm" weight="lg">
                  {translate("home", "Home")}
                </TextCom>
              </Link>
            </li>
            <li>
              <Link to="/product">
                <TextCom size="sm" weight="lg">
                  {translate("product", "Product")}
                </TextCom>
              </Link>
            </li>
            <li>
              <Link to="/aboutus">
                <TextCom size="sm" weight="lg">
                  {translate("about-us", "About Us")}
                </TextCom>
              </Link>
            </li>
            <li>
              <Link to="/contactus">
                <TextCom size="sm" weight="lg">
                  {translate("contact-us", "Contact Us")}
                </TextCom>
              </Link>
            </li>
          </ul>
        </div>
        <div className="shopping">
          <SearchCom />
          <div className="shop-cart">
            <Link to="/order/cart">
              <FiShoppingCart size={25} />
            </Link>
            {/* {cartList?.data?.order_lines?.length > 0 &&  */}
            <>
              {getCart_data?.cart_item_count > 0 && <div className="cart-badge">
                <TextCom color="light" size="xs">
                  {getCart_data?.cart_item_count}
                </TextCom>
              </div>}
              <div className="cart-popup">
                <ul className="cart-list">
                  {order_list?.length > 0 && order_list?.map((item, i) => (
                    <li className="cart-item">
                      <img src={require("../../assets/images/no-img.jpg")} alt="" />
                      <div className="content">
                        <TextCom color="TextCom" size="md" cu_weight="700">{item?.name}</TextCom>
                        <TextCom color="TextCom" size="md">{translate("qty", "Qty")}: {item?.quantity}</TextCom>
                        <div className="price">
                          {/* {x?.promo_price ? ( */}
                          <TextCom color="TextCom" size="sm" weight="lg">
                            <span>
                              {moneyFormat(Math.ceil(1000), langCode)}{" "}
                              {translate("ks", "Ks")}
                            </span>
                            <del>
                              {moneyFormat(2000, langCode)}{" "}
                              {translate("ks", "Ks")}
                            </del>
                            <span className="percent">-{50}%</span>
                          </TextCom>
                          {/* ) : (
                              <TextCom>
                                <span style={{ marginRight: 5 }}>
                                  {Number(x?.list_price) !== 0 &&
                                    moneyFormat(x?.list_price, langCode)}
                                </span>
                                <span>
                                  {Number(x?.list_price) !== 0 &&
                                    translate("ks_unit", "Ks")}
                                </span>
                              </TextCom> */}
                          {/* )} */}
                        </div>
                      </div>
                      <Link
                        to={"/product/" + 2}
                        className="product-detail-link"
                      >

                      </Link>
                      <div
                        className="remove-item"
                        onClick={
                          (_) => <></>
                          // removeCartItemHandler(item.product_id)
                        }
                      >
                        <MdOutlineHighlightOff />
                      </div>
                    </li>
                  ))
                  }
                </ul>
                <div className="summary">
                  <TextCom size="lg" weight="700">
                    {translate("total", "Total")}
                  </TextCom>
                  {/* <TextCom>{moneyFormat(cartList.data?.total, langCode)}{" "}
                          {translate("ks", "Ks")}</TextCom> */}
                  <TextCom size="lg" weight="700">
                    {moneyFormat(1000, langCode)} {translate("ks", "Ks")}
                  </TextCom>
                </div>
                {location?.pathname !== "/order/cart" && (
                  <Link to="/order/cart" className="go-cart-btn">
                    <ButtonCom btnBorderRadius="xxxs" color='light' primary text={translate("go-to-cart", "Go to cart")} />
                  </Link>
                )}
              </div>
            </>
            {/* } */}
          </div>
        </div>
      </div>
    </SecondaryNavContainer>
  );
};

export default SecondaryNav;
