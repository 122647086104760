import { lazy } from 'react'

const staticpage = lazy(() => import('./staticpage'))
const aboutus = lazy(() => import('./aboutus'))
const contactus = lazy(() => import('./contactus'))

const Static = {
  staticpage,
  aboutus,
  contactus
}

export default Static