import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { cart as CartAction } from 'store/actions'

type Props = {}

export const useCart = (props: Props) => {
  const cart = useSelector(state => state.cart, shallowEqual)
  const dispatch = useDispatch()


  return {
    ...cart,
    CartAction,
    dispatch,
    useSelector,
    shallowEqual
  }
}