import { useCart, useProduct } from '../../common'

type Props = {}

let tmp = null
let timeout = 500
export const useHandleQty = ({ type = 'productbyid', item, w_index = 0, c_index = 0, ...props }: Props) => {
  const { getProductById_data, getWishlist_data, checkStock_isLoading, ProductAction, dispatch } = useProduct()
  const { createCart_data, getCart_data, updateQtyInCart_isLoading, CartAction } = useCart()

  const handleQty = () => {
    if (type === 'productbyid') {
      return getProductById_data?.quantity || 1
    } else if (type === 'wishlist') {
      return getWishlist_data?.[w_index]?.quantity || 1
    } else if (type === 'cartlist') {
      return getCart_data?.order_lines?.[c_index]?.quantity || 1
    } else {
      return 1
    }
  }

  const handleQtyFun = async qty => {
    if (type === 'productbyid') {
      let pos_data = {
        type: 'productbyid',
        item: getProductById_data,
        product_id: getProductById_data?.product_id,
        qty
      }
      if (getProductById_data?.attributes?.data?.length > 0) {
        pos_data.variant_id = getProductById_data?.attributes?.selected_variant_ids?.length > 0 ? getProductById_data?.attributes?.selected_variant_ids : null
      }
      if (!checkStock_isLoading) {
        if (qty > 0) {
          await dispatch(ProductAction.setProductDetail({ key: 'quantity', data: qty }))
        }
        clearTimeout(tmp)
        tmp = setTimeout(async () => {
          getProductById_data?.product_id && await dispatch(ProductAction.checkStock(pos_data))
        }, timeout)
      }
    } else if (type === 'wishlist') {
      let pos_data = {
        type: 'wishlist',
        product_id: item?.product_template_id,
        w_index,
        item,
        qty
      }
      if (!checkStock_isLoading) {
        if (qty > 0) {
          await dispatch(ProductAction.setWishlistList({ key: 'quantity', w_index, data: qty }))
        }
        clearTimeout(tmp)
        tmp = setTimeout(async () => {
          await dispatch(ProductAction.checkStock(pos_data))
        }, timeout)
      }
    } else if (type === 'cartlist') {
      let pos_data = {
        order_id: createCart_data?.order_id,
        product_id: item?.product_template_id,
        variant_id: item?.variant_id,
        c_index,
        item,
        qty
      }
      if (!item?.variant_id) {
        delete pos_data.variant_id
      }
      if (!updateQtyInCart_isLoading) {
        if (qty > 0) {
          let obj = {
            c_index, qty
          }
          await dispatch(CartAction.setQtyInCartList(obj))
        }
        clearTimeout(tmp)
        tmp = setTimeout(async () => {
          createCart_data?.order_id && await dispatch(CartAction.updateQtyInCart(pos_data))
        }, timeout)
      }
    }
  }

  return [handleQty(), handleQtyFun]
}