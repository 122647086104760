import styled from "styled-components";
import { rgba } from "polished";
import { Text } from "../typo";
import { media, fontSize, colors, generalGap } from "../../attribute";

const CategorySideBarContainer = styled.div`
  position: relative;
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
  }
`;

const SideBarUl = styled.ul`
  margin-bottom: 30px;
  li {
    border-radius: 10px;
  }
`;

const SideBarTitle = styled.li`
  background-color: ${colors.label};
  color: ${colors.light};
  margin-bottom: 5px;
  padding: 10px 20px;
`;

const SideBarList = styled.li`
  background-color: ${colors.light};
  border-bottom: 1px dashed ${colors.text};
  color: ${colors.label};
  font-size: ${fontSize.sm}px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:hover,
  &.active {
    background-color: ${colors.primaryBackground};
    color: ${colors.secondary};
  }
`;

const SideBarDrp = styled(SideBarList)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  margin-bottom: 2px;
  .sub-drp {
    min-width: 210px;
    position: absolute;
    top: 45px;
    left: initial;
    right: 0;
    padding-left: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
    z-index: 9;
    @media screen and (min-width: ${media.md}px) {
      top: 0;
      right: initial;
      left: 100%;
    }
    ul {
      border-radius: 10px;
      background-color: ${colors.light};
      box-shadow: 0px 0px 10px rgba(${colors.secondary}, .2)};
      padding: 5px;
      li {
        border-radius: 5px;
      }
    }
  }
  &:hover {
    .sub-drp {
      opacity: 1;
      visibility: visible;
    }
  }
  span {
    display: block;
    width: 100%;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

const SideBarFilter = styled.div`
  margin-bottom: 30px;
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      margin-right: 5px;
      margin-bottom: 0;
    }
    button {
      min-width: 50px;
      min-height: 35px;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      height: 35px;
      padding: 0 10px;
      font-size: ${fontSize.sm}px;
    }

    .btn-pname {
      padding: 10px;
      min-width: 80px;
    }
  }
  .custom-check,
  .custom-radio {
    margin: 5px;
  }

  .check-item-con {
    /* max-height: 290px; */
    overflow: auto;
  }
`;

const FilterTitle = styled(Text)`
  position: relative;
  color: ${colors.secondary};
  font-size: ${fontSize.md};
  font-weight: 700;
  /* padding-bottom: 10px; */
  margin-bottom: 5px;
  /* &:before {
    content: '';
    width: 20px;
    height: 3px;
    background-color: ${colors.filterBorder};
    position: absolute;
    bottom: 0;
    left: 0;
  } */
`;

// sidebar style
const SidebarContainer = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-bottom: 20px;
  border: 1px solid ${props => props?.theme?.color?.typo?.gray2};
  border-radius: 5px;
  padding-inline: 32px;
  @media(max-width: 768px){
    padding-inline: 16px;
  }
  li {
    border-bottom: 1px solid ${props => props?.theme?.color?.typo?.gray2};
    a {
      display: block;
      color: ${props => props?.theme?.color?.typo?.label};
      transition: color 0.3s ease;
      padding: 15px;
      padding-inline: 0; 
      cursor: pointer;
      transition: background-color 0.2s ease;
      text-decoration: none;
      &.active,
      &:hover {
        color: ${props => props?.theme?.color?.typo?.primary};
      }
    }
    &:last-child {
        border: none;
    }
  }
  @media(max-width: 767px){
    display: flex;
    justify-content: space-between;
    
    li {
        border: none;
         a{
          &.active{
          color: ${props => props?.theme?.color?.typo?.text};
          border-bottom: 2px solid ${props => props?.theme?.color?.typo?.primary}
        }
      }
    }
  }
`;

export {
  CategorySideBarContainer,
  SideBarUl,
  SideBarTitle,
  SideBarList,
  SideBarDrp,
  SideBarFilter,
  FilterTitle,
  SidebarContainer,
};
