import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiPhoneCall, FiMail, FiUser, FiList, FiHeart, FiLogOut } from "react-icons/fi";

import { PrimaryNavContainer } from "theme";
import { useOther, useAuth, useHandleAuth, useHandleOther } from "hook";
import LanguageSwitch from "./components/LangSwitch";
import { TextCom } from "components";


const PrimaryNav = props => {
  const { langStore, getContactUsPage_data, dispatch, OtherAction } = useOther();
  const { getProfile_data, AuthAction } = useAuth();
  const { handleSignOut } = useHandleAuth(props)
  const { translate } = useHandleOther();
  const langCode = langStore?.code;


  const HandleSignOut = async () => {
    await dispatch(AuthAction.signOut());
  }

  const handleClick = (type: string) => {
    if (type === 'logout') {
      handleSignOut()
    }
  }

  return (
    <PrimaryNavContainer>
      <div className="container primary-nav">
        <div className="contact">
          <div className="call-to">
            <a href={`tel:09422 656 373`}>
              <img src={require("../../assets/icons/telephone.png")} />
              {/* <FiPhoneCall/> */}
              {getContactUsPage_data ? getContactUsPage_data[0]?.hot_line_phone : "09xxx xxx xxx"}
            </a>
          </div>
          <div className="mail-to">
            <a href={`mailto:someone@example.com`}>
              <img src={require("../../assets/icons/mail.png")} />
              {/* <FiMail/> */}
              {getContactUsPage_data ? getContactUsPage_data[0]?.email : "innovixcommerce@domain.com"}
            </a>
          </div>
        </div>
        <div className="user-language">
          <div className="login-option">
            {getProfile_data?.name ? (
              <div className="user-profile">
                <FiUser size={20} />
                <Link to={"/user/profile"}>
                  <TextCom size="sm" weight="lg">
                    {getProfile_data?.name}
                  </TextCom>
                </Link>
                <ul className="menu">
                  <li>
                    <FiList />
                    <Link to={'/user/my_orders'}>
                      <TextCom weight="lg">
                        {translate("my-order", 'My Order')}
                      </TextCom>
                    </Link>
                  </li>
                  <li>
                    <FiUser />
                    <Link to={"/user/profile"}>
                      <TextCom weight="lg">
                        {translate("my-profile", "My profile")}
                      </TextCom>
                    </Link>
                  </li>
                  <li>
                    <FiHeart />
                    <Link to={"/user/wishlist"}>
                      <TextCom weight="lg">
                        {translate("my-wishlist", "My wishlist")}
                      </TextCom>
                    </Link>
                  </li>
                  <li onClick={HandleSignOut}>
                    <FiLogOut />
                    <TextCom weight="lg">
                      {translate("logout", "Logout")}
                    </TextCom>
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <Link to={"/user/signin"}>
                  <TextCom size="sm" weight="lg">
                    {translate("Login", "Login")}
                  </TextCom>
                </Link>
                <Link to={"/user/signup"}>
                  <TextCom size="sm" weight="lg">
                    {translate("signup", "Sign Up")}
                  </TextCom>
                </Link>
              </>
            )}
          </div>
          <LanguageSwitch />
        </div>
      </div>
    </PrimaryNavContainer>
  );
};

export default PrimaryNav;
