import _ from 'underscore'

const { fromJS } = require('immutable')
let CryptoJS = require('crypto-js')

const Rating = rating => {

  // if (rating == 0) { return null }

  // let stars = []

  // for (let i = 1; i <= 5; i++) {
  //   if (i <= rating) {
  //     stars.push(require('@asset/icon/full-star.svg'))
  //   } else if (i <= Math.ceil(rating)) {
  //     stars.push(require('@asset/icon/half-star.svg'))
  //   } else {
  //     stars.push(require('@asset/icon/empty-star.svg'))
  //   }
  // }

  // return (
  //   stars?.map((x, i) => (
  //     <img src={x} alt='star' key={i} />
  //   ))
  // )
}

const moneyFormat = (amount = 0, lang = 'en') => {
  let eng_money = Number(amount).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  let tmp = { '0': '၀', 1: '၁', 2: '၂', 3: '၃', 4: '၄', 5: '၅', 6: '၆', 7: '၇', 8: '၈', 9: '၉' }
  let mm_money = eng_money.replace(/([0-9])/g, (s, key) => tmp[key] || s)
  return lang === 'en' ? eng_money : mm_money
}

const en2mm_num = (num = 0, lang = 'en') => {
  let strnum = num.toString()
  let tmp = { '0': '၀', 1: '၁', 2: '၂', 3: '၃', 4: '၄', 5: '၅', 6: '၆', 7: '၇', 8: '၈', 9: '၉' }
  let mm_tmp = strnum.replace(/([0-9])/g, (s, key) => tmp[key] || s)
  return lang === 'en' ? num : mm_tmp
}

const groupBy = (data, key) => {
  return data.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

const uniqueArray = (data, key) => {
  return [
    ...new Map(
      data.map(x => [key(x), x])
    ).values()
  ]
}

const getUiTranslate = (dict = { loading: { en: 'Loading', my: 'လုပ်ဆောင်နေသည်' } }, entry, lang = 'en', def) => {
  if (dict) {

    return dict?.[entry]?.[lang] || def || entry
  }
}

// const getUiTranslate = (dict, entry, lang = 'en') => {
//   return dict?.data?.length > 0 && _.find(dict?.data, item => _.keys(item)[0] === entry)?.[entry][lang === 'en' ? 'english' : 'unicode']
// }

const addCheckDefault = (data, key) => {
  let tmp = data.map(x => ({ ...x, [key]: false }))
  return tmp
}

// getBrandList_data = {
//   data: [],
//   selected_ids: [1,3,5],
//   selected_objs:[{}, {}, {}]
// }

const checkChange = (type, checkListItem, data, mode = 'multi') => {
  let arr = []
  let final_arr = []

  if (mode === 'single') {
    checkListItem?.data?.map(x => {
      arr.push({
        ...x,
        check: false
      })
    })
  } else {
    arr = checkListItem?.data
  }

  arr?.map((x) => {
    if (x[type] === data) {
      final_arr.push({
        ...x,
        check: !x.check
      })
    } else {
      final_arr.push({
        ...x
      })
    }
  })
  return final_arr

  // if (mode === 'single') {
  //   checkListItem.map(x => x.check = false)
  // }
  // const changedCheckbox = checkListItem.find(x => x[type] === data)
  // changedCheckbox.check = !changedCheckbox.check
  // return checkListItem
}

const getCheckId = (type, data) => {
  let temp = []
  data.map((x) => x.check === true && temp.push(x[type]))
  return temp
}

const handleStatus = (dict = { loading: { en: 'Loading', my: 'လုပ်ဆောင်နေသည်' } }, status = 'loading', langCode = 'en') => {
  return getUiTranslate(dict, status, langCode)
}

const dataEncrypt = (data, key) => {
  // Encrypt
  let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
  return ciphertext
}

const dataDecrypt = (data, key) => {
  // Decrypt
  let bytes = CryptoJS.AES.decrypt(data, key)
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

const youtube_parser = url => {
  if (url) {
    var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    var match = url.match(regExp);
    return (match && match[1].length == 11) ? match[1] : false;
  }
}

// let data = {
//     att_data,
//     att_id,
//     sub_id
// }
const wishlistHandleChange = data => {
  let selected = []
  let obj_selected = []
  let arr = []
  if (data?.att_data?.length > 0) {
    arr = data?.att_data?.map(x => {
      let par_obj = {
        ...x
      }
      if (x.id === data?.att_id) {
        let sub = []
        if (x?.vals?.length > 0) {
          sub = x?.vals?.map(y => {
            let obj = {
              ...y
            }
            if (y.id === data?.sub_id) {
              obj.check = !obj?.check
            } else {
              obj.check = false
            }
            return obj
          })
        }
        par_obj.vals = sub
      } else {
        // console.log('x?.vals', x?.vals)
      }
      if (par_obj?.vals?.length > 0) {
        par_obj?.vals?.map(e => {
          if (e.check) {
            selected.push(e.id)
            obj_selected.push(e)
          }
        })
      }
      return par_obj
    })
  }
  let add_total = obj_selected.reduce((total, value) => total + value.price, 0)
  let final_arr_obj = {
    selected_variant_ids: selected,
    obj_selected_variant: obj_selected,
    extra_price_for_variant: add_total,
    data: arr
  }
  return final_arr_obj
}

const UnitArray = async (data, type) => {
  const uniqueIds = []

  const unique = data.filter(element => {
    const isDuplicate = uniqueIds.includes(element[type])

    if (!isDuplicate) {
      uniqueIds.push(element[type])

      return true
    }

    return false
  })
  return unique
}

const handleNestedChange = (data: any[], type: string, nst_type: string) => {
  let n_arr = data

  const handleInnerNested = (data: any, type: string, nst_type: string, index: any) => {
    let dem_arr = data

    if (dem_arr?.length > 0) {
      let tmp = dem_arr?.map((x, i) => {
        let tmp_i = `${index}-${i}`
        if (x[nst_type].length > 0) {
          x.key = `${tmp_i}-${x[type]}`
          handleInnerNested(x[nst_type], type, nst_type, tmp_i)
        } else {
          x.key = `${tmp_i}-${x[type]}`
        }
      })
    }
  }

  if (n_arr?.length > 0) {
    let tmp = n_arr?.map((x, i) => {
      let tmp_i: any = i.toString()
      if (x[nst_type].length > 0) {
        x.key = `${tmp_i}-${x[type]}`
        handleInnerNested(x[nst_type], type, nst_type, tmp_i)
      } else {
        x.key = `${tmp_i}-${x[type]}`
      }
    })
  }
  return n_arr
}

export {
  Rating,
  moneyFormat,
  groupBy,
  uniqueArray,
  addCheckDefault,
  checkChange,
  getCheckId,
  getUiTranslate,
  handleStatus,
  dataEncrypt,
  dataDecrypt,
  en2mm_num,
  youtube_parser,
  wishlistHandleChange,
  UnitArray,
  handleNestedChange
}