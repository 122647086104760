import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { theme as ThemeAction } from 'store/actions'

type Props = {}

export const useTheme = (props: Props) => {
  const theme = useSelector(state => state.theme, shallowEqual)
  const dispatch = useDispatch()

  return {
    ...theme,
    ThemeAction,
    dispatch,
    useSelector,
    shallowEqual
  }
}