import ArrowLeft from './arrowleft.svg'
import BubbleSvg from './bubble.svg'
import CartSvg from './Cart.svg'
import Cart1Svg from './Cart-1.svg'
import DocumentSvg from './document.svg'
import EarthSvg from './earth.svg'
import HomeSvg from './Home.svg'
import Home1Svg from './Home-1.svg'
import MenuSvg from './menu.svg'
import MoreSvg from './More.svg'
import More1Svg from './More-1.svg'
import MySvg from './my.svg'
import PhoneSvg from './phone.svg'
import ProfileUserSvg from './profile-user.svg'
import ProfileSvg from './Profile.svg'
import Profile1Svg from './Profile-1.svg'
import SearchSvg from './search.svg'
import ShieldSvg from './shield.svg'
import UkSvg from './uk.svg'
import CheckPaymentSvg from './check_payment.svg'
import AddCircleSvg from './add_circle.svg'
import SubCircleSvg from './sub_circle.svg'
import TrashSvg from './trash.svg'
import UserSvg from './user-image.svg'
import HeartSvg from './heart.svg'
import ActiveHeartSvg from './active_heart.svg'
import UnCheckPaymentSvg from './uncheck_payment.svg'
import EmptyCartSvg from './empty_cart.svg'
import LocationSvg from './location.svg'
import ContactPhoneSvg from './contact_phone.svg'
import EmailSvg from './black-back-closed-envelope-shape.svg'
import SuccessCartSvg from './success_cart.svg'
import AddingCircleSvg from './adding_circle.svg'
import OrderSuccessSvg from './order_success.svg'
import PlaceholderSvg from './placeholder.svg'
import PencilSvg from './pencil.svg'
import FilterSvg from './filter.svg'
import TrashDelSvg from './trash_delete.svg'
import engImage from './eng.svg'
import myImage from './my.svg'
import Location from './location.svg'
import Mail from './mail.svg'
import Telephone from './telephone.svg'
import Cbpay from './cbpay.png'
import Kbzpay from './kbzpay.png'
import Wavepay from './wavepay.png'
import leftArrow from './arrow-left.svg'
import rightArrow from './arrow-right.svg'
import MaskGpSvg from './mask_gp.svg'
import IconTickSvg from './icon_tick.svg'
import ratingStart from './star.svg'
import SuccessSvg from './success.svg'
import WarningSvg from './warning.svg'
import ConfirmSvg from './confirm.svg'
import ErrorSvg from './Error.svg'

export default {
  ratingStart,
  rightArrow,
  leftArrow,
  Location,
  Mail,
  Telephone,
  Cbpay,
  Kbzpay,
  Wavepay,
  myImage,
  engImage,
  ArrowLeft,
  BubbleSvg,
  CartSvg,
  DocumentSvg,
  EarthSvg,
  HomeSvg,
  MenuSvg,
  MoreSvg,
  MySvg,
  PhoneSvg,
  ProfileUserSvg,
  ProfileSvg,
  SearchSvg,
  ShieldSvg,
  UkSvg,
  CheckPaymentSvg,
  SubCircleSvg,
  AddCircleSvg,
  TrashSvg,
  UserSvg,
  Home1Svg,
  More1Svg,
  Cart1Svg,
  Profile1Svg,
  HeartSvg,
  ActiveHeartSvg,
  UnCheckPaymentSvg,
  EmptyCartSvg,
  LocationSvg,
  ContactPhoneSvg,
  EmailSvg,
  SuccessCartSvg,
  AddingCircleSvg,
  OrderSuccessSvg,
  PlaceholderSvg,
  PencilSvg,
  FilterSvg,
  TrashDelSvg,
  MaskGpSvg,
  IconTickSvg,
  SuccessSvg,
  WarningSvg,
  ConfirmSvg,
  ErrorSvg
}