import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { auth as AuthAction } from  'store/actions'

type Props = {}

export const useAuth = (props: Props) => {
  const auth = useSelector(state => state.auth, shallowEqual)
  const dispatch = useDispatch()


  return {
    ...auth,
    AuthAction,
    dispatch,
    useSelector,
    shallowEqual
  }
}