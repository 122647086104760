import { createGlobalStyle } from 'styled-components'
import { fontSize, fontWeight, General, colors } from '.'

const handleFontFamily = props => {
  let lang_data = {
    my: `'Montserrat', sans-serif`,
    en: `'Montserrat', sans-serif`
  }
  let tmp = lang_data[props.theme.langStore.code || 'en']
  return tmp
}

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  ::selection {
    background-color: ${colors.primary};
    color: ${colors.light};
  }

  html {
    scroll-behavior: smooth;
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: ${props => {
    let res = handleFontFamily(props)
    /* console.log('font res', res) */
    return res
  }};
    font-size: ${props => props.theme.fontSize[props?.size] || props.size || props.theme.fontSize.md}px;
    line-height: 1.5;
    font-weight: ${props => props.theme.fontWeight[props?.weight] || props.theme.fontWeight.md};
    text-align: left;
    background-color: ${props => props.theme.colors.light || '#fff'};
    /* transition: transform .3s ease; */

    &.open-drawer {
      transform: translateX(-250px);
      height: 100vh;
      min-height: 100vh;
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      .menu-btn {
        border: 2px solid ${colors.light} !important;
        border-radius: 50% !important;
        .menu-icon {
          transform: translateX(-50px) !important;
          background: transparent !important;
          &:before,
          &:after {
            right: 6px !important;
            width: 18px !important;
          }
          &:before {
            transform: rotate(45deg) translate(35px, -35px) !important;
            background: ${colors.light} !important;
          }

          &:after {
            transform: rotate(-45deg) translate(35px, 35px) !important;
            background: ${colors.light} !important;
          }
        }
      }
    }
    .opacity0 {
      opacity: 0;
    }

    .quantity {
      display: flex;
      align-items: center;
      .quantity-drp {
        display: flex;
        align-items: center;
        justify-content: center;

        &.promo-qty{
          .q-btn {
            width: 32px;
            text-align: center;
            padding: 7px 0;
            background-color: ${colors.light};
            color: ${colors.label};
            border: 1px solid ${colors.light};
            font-weight: ${fontWeight.xxl};
            cursor: pointer;
            &.decrement {
              border-radius: 15px 0 0 15px;
              user-select: none;
            }
            &.increment {
              border-radius: 0 15px 15px 0;
              user-select: none;
            }
          }

          input {
            text-align: center;
            width: 60px;
            border-radius: 0;
            padding: 7px 5px;
          }
        }

        input {
          text-align: center;
          width: 60px;
          border-radius: 0;
        }
        .q-btn {
          width: 46px;
          text-align: center;
          padding: 7px 15px;
          background-color: ${colors.light};
          color: ${colors.label};
          border: 1px solid ${colors.light};
          font-weight: ${fontWeight.xxl};
          cursor: pointer;
          &.decrement {
            border-radius: 15px 0 0 15px;
            user-select: none;
          }
          &.increment {
            border-radius: 0 15px 15px 0;
            user-select: none;
          }
        }
      }
    }
  }
`