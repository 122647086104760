import { useEffect, useState } from 'react'
import { useProduct, useCart, useOther } from '../../common'
import { useNavigate } from 'react-router-dom'

const queryString = require('query-string')
type Props = {}

export const useHandleProduct = (props: Props) => {
  const navigate = useNavigate()
  const [sorting, setSorting] = useState('')
  const [price, setPrice] = useState({
    min_price: '',
    max_price: '',
    price_filter: false
  })
  const { getBrandList_data, getProductCategory_data, dispatch, ProductAction } = useProduct()
  const { getAllLocation_data, CartAction } = useCart()
  const { langStore } = useOther()
  let langCode = langStore?.code

  useEffect(() => {
    let filter_obj = {
      category_ids: getProductCategory_data?.selected_ids,
      brand_ids: getBrandList_data?.selected_ids,
      location_ids: getAllLocation_data?.selected_ids,
      min_price: price?.min_price || '',
      max_price: price?.max_price || ''
    }
    const stringified = queryString.stringify(filter_obj, { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' })
    navigate({
      pathname: '/product',
      search: `?${stringified}`
    })
  }, [getAllLocation_data?.selected_ids, getBrandList_data?.selected_ids, getProductCategory_data?.selected_ids, price?.price_filter])

  type handleProductFilterProps = {
    type?: string, // category, brand, location, min_price, max_price
    event?: any,
    id?: any,
    key?: string
  }
  const handleProductFilterChange = ({ type, event, id, key, ...obj }: handleProductFilterProps) => {
    if (type === 'category') {
      handleCategoryCheck(key)
    } else if (type === 'brand') {
      handleBrandsCheck(id)
    } else if (type === 'location') {
      handleLocationCheck(id)
    } else if (type === 'min_price') {
      handlePriceChange(type, event)
    } else if (type === 'max_price') {
      handlePriceChange(type, event)
    }
  }

  const handleCategoryCheck = async (key: string) => {
    dispatch(ProductAction.setProductCategory({ key }))
  }

  const handleBrandsCheck = (id: any) => {
    dispatch(ProductAction.setBrandList({ type: 'ID', checkListItem: getBrandList_data, data: id }))
  }

  const handleLocationCheck = (id: any) => {
    dispatch(CartAction.setLocationList({ type: 'id', checkListItem: { data: getAllLocation_data?.data?.[0]?.state_ids }, data: id }))
  }

  const handlePriceChange = (type: string, event: any) => {
    if (type === 'min_price') {
      let min_price: any = Number(event.target.value) || ''
      setPrice({
        ...price,
        min_price
      })
    } else if (type === 'max_price') {
      let max_price: any = Number(event.target.value) || ''
      setPrice({
        ...price,
        max_price
      })
    }
  }

  const handlePriceFilter = async () => {
    let req_obj = {
      lang: langCode,
      page_number: 1,
      per_page: 12,
      category_id: getProductCategory_data?.selected_ids,
      brand_id: getBrandList_data?.selected_ids,
      country_id: getAllLocation_data?.selected_ids,
      min_price: price?.min_price || '',
      max_price: price?.max_price || '',
      sort_by: sorting
    }
    await dispatch(ProductAction.getProduct(req_obj))
  }

  let sort_by = [
    {
      name: 'Price from low',
      value: 'PRICEFROMLOW'
    },
    {
      name: 'Price from height',
      value: 'PRICEFROMHIGH'
    }
  ]

  return {
    handleCategoryCheck,
    handleBrandsCheck,
    handleLocationCheck,
    handlePriceFilter,
    handlePriceChange,
    handleProductFilterChange,
    price,
    setPrice,
    sort_by,
    sorting,
    setSorting
  }
}