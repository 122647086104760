import styled from 'styled-components'
import Slider from 'react-slick'

export const StyledRSSlider = styled(Slider)`
  display: block;
  .slick-list{
    padding-bottom: 10px;
  }

  .slick-slide {

  }

  .prev{
    left: 0
  }

  .next{
    right: 0;
    top: 0
  }

  .prev, .next{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    height: 100%;
    align-items: center;
    z-index: 99999;
    cursor: pointer;
    transition: opacity .3s ease-in-out;

    &:hover, &:focus {
      svg{
        opacity: .7;
      }
    }
  }

  .slick-arrow {
    &.slick-prev, &.slick-next{
      background-color: rgb(187, 184, 184);
      border-radius: 10px;
  
      &:hover, &:focus {
        background-color: rgb(187, 184, 184);
        border-radius: 10px;
      }
    }
  }

  &.slide-nav{
    .slick-list{
      padding-bottom: 0;
      margin: 0 13px;
    }
     
    .slick-slide{
      padding: 0 6px;
      cursor: pointer;

      img{
        border: 1px solid ${props => props?.theme?.color?.card?.bg1 || 'transparent'};
        border-radius: ${props => props?.theme?.RSVariable?.nav?.img_border_radius || 0}px;
      }
    }
  }

  .slick-dots {
    li {
      margin: 0;
      button {
        &:before {
          font-size: 11px;
          opacity: .5;
          color: ${props => props?.theme?.color?.card?.light || '#fff'};
        }
      }
      &.slick-active {
        button {
          &:before {
            opacity: 1;
            color: ${props => props?.theme?.color?.card?.light || '#fff'};
          }
        }
      }
    }
  }
`

export const StyledCustomSlide = styled.div`
  
`