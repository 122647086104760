import React from 'react'

import { TextCom } from '../../common'
import { useOther, useProduct } from 'hook'

type Props = {}

export const StockVariantMsgMod = (props: Props) => {
  const { from, item } = props
  const { getProductById_data } = useProduct()
  const { langStore, dictionary_data } = useOther()
  let langCode = langStore.code
  let from_data = from === 'cartlist' ? item : getProductById_data

  return (
    from === 'cartlist' ?
      <>
        {
          item?.check_stock?.desc ?
            <TextCom color='danger' size='xs'>{item?.check_stock?.desc}</TextCom>
            :
            item?.check_stock?.available_qty > 0 ?
              item?.check_stock?.available_qty < 10 ?
                <TextCom color='danger' size='xs'>{langCode === 'en' ? `Only ${item?.check_stock?.available_qty} left in stock` : `${item?.check_stock?.available_qty} ခုသာ ကျန်ပါတော့သည်`}</TextCom>
                :
                (item?.check_stock?.instock === false && <TextCom color='danger' size='xs'>{langCode === 'en' ? `Only ${item?.check_stock?.available_qty} left in stock` : `${item?.check_stock?.available_qty} ခုသာ ကျန်ပါတော့သည်`}</TextCom>)
              :
              item?.check_stock?.instock === false && <TextCom color='danger' size='xs'>out_of_stock</TextCom>

        }
      </>
      :
      <>
        {
          getProductById_data?.check_stock?.desc ?
            <TextCom color='danger' size='xs'>{getProductById_data?.check_stock?.desc}</TextCom>
            :
            getProductById_data?.check_stock?.available_qty > 0 ?
              getProductById_data?.check_stock?.available_qty < 10 ?
                <TextCom color='danger' size='xs'>{langCode === 'en' ? `Only ${getProductById_data?.check_stock?.available_qty} left in stock` : `${getProductById_data?.check_stock?.available_qty} ခုသာ ကျန်ပါတော့သည်`}</TextCom>
                :
                (getProductById_data?.check_stock?.in_stock === false && <TextCom color='danger' size='xs'>{langCode === 'en' ? `Only ${getProductById_data?.check_stock?.available_qty} left in stock` : `${getProductById_data?.check_stock?.available_qty} ခုသာ ကျန်ပါတော့သည်`}</TextCom>)
              :
              getProductById_data?.check_stock?.in_stock === false && <TextCom color='danger' size='xs'>out_of_stock</TextCom>

        }
      </>
  )
}