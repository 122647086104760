import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { product as ProductAction } from 'store/actions'

type Props = {}

export const useProduct = (props?: Props) => {
  const product = useSelector(state => state.product, shallowEqual)
  const dispatch = useDispatch()

  return {
    ...product,
    ProductAction,
    dispatch,
    useSelector,
    shallowEqual
  }
}