import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import serviceController, { routes, routeFilter } from 'controller'
import { isPendingAction, isFulfilledAction, isRejectedAction } from '../typehandle.action'
import { checkChange } from 'utils'

const initialState = {
  isLoading: false,
  isBottomReach: false,
  error: null,
  order_offset: 1,
  order_limit: 10,
}

const getCart = createAsyncThunk('cartSlice/getCart', async (data, thunkAPI) => {
  let { cart, other } = thunkAPI.getState()
  return await serviceController(`${routes.getCart}${cart?.createCart_data?.order_id || data?.order_id}?lang=${other?.langStore?.code || 'en'}`)
    .then(res => {
      if (res?.data) {
        // sorting order line with product_type (service | real product)
        let tmp = res?.data?.order_lines?.sort((a, b) => a.product_type > b.product_type ? 1 : -1)
        // calc cart product item count 
        let cart_item = tmp?.filter(x => x.product_type === 'product')
        let cart_item_count = cart_item.reduce((total, value) => total + value.quantity, 0)
        return {
          ...res?.data,
          cart_item_count,
          order_lines: tmp
        }
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

type CreateCartProps = {
  langCode: string,
  product_id: number,
  qty: number,
  variant_id: any
}
const createCart = createAsyncThunk('cartSlice/createCart', async (data: CreateCartProps, thunkAPI) => {
  let { langCode, ...field } = data
  let final_req_obj = field?.variant_id ? { ...field, variant_id: field?.variant_id?.toString() } : { ...field }
  return await serviceController(routes.createCart, final_req_obj)
    .then(async res => {
      if (res?.data) {
        await thunkAPI.dispatch(getCart({ order_id: res?.data?.order_id }))
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const updateQtyInCart = createAsyncThunk('cartSlice/updateQtyInCart', async (data, thunkAPI) => {
  let { item, c_index, ...field } = data
  return await serviceController(routes.updateQtyInCart, field)
    .then(async res => {
      if (res?.data?.status !== 'fail') {
        await thunkAPI.dispatch(getCart())
        await thunkAPI.dispatch(cartSlice.actions.setCartList({ key: 'check_stock', data: res?.data, c_index, req_data: data }))
        return res?.data
      } else {
        let dum_data = {
          ...data,
          qty: res.data.available_qty
        }
        await thunkAPI.dispatch(updateQtyInCart(dum_data))
        return res?.data
      }
    })
    .catch(error => error.message)
})

const updateCustomer = createAsyncThunk('cartSlice/updateCustomer', async (data) => {
  return await serviceController(routes.updateCustomer, data)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const deleteCart = createAsyncThunk('cartSlice/deleteCart', async (data, thunkAPI) => {
  let { langCode = 'en', ...field } = data
  return await serviceController(routes.deleteCart, { data: field })
    .then(async res => {
      if (res?.data) {
        await thunkAPI.dispatch(getCart())
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const deleteAllInCart = createAsyncThunk('cartSlice/deleteAllInCart', async (data, thunkAPI) => {
  let { langCode = 'en', ...field } = data
  return await serviceController(routes.deleteAllInCart, { data: field })
    .then(async res => {
      if (res?.data) {
        await thunkAPI.dispatch(getCart())
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getShippingAddress = createAsyncThunk('cartSlice/getShippingAddress', async (data, thunkAPI) => {
  return await serviceController(routes.getShippingAddress)
    .then(res => {
      if (res?.data) {
        let final_res = {
          data: res?.data
        }
        return final_res
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getShippingAddressById = createAsyncThunk('cartSlice/getShippingAddressById', async (id, thunkAPI) => {
  return await serviceController(`${routes.getShippingAddressById}${id}`)
    .then(async res => {
      if (res?.data) {
        return res?.data[0]
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const createShippingAddress = createAsyncThunk('cartSlice/createShippingAddress', async (data, thunkAPI) => {
  let { auth, cart } = thunkAPI.getState()
  return await serviceController(routes.createShippingAddress, data)
    .then(async res => {
      if (res?.data?.status === 'success') {
        if (auth?.getProfile_data) {
          await thunkAPI.dispatch(getShippingAddress())
          let getShippingAddressById_res = await thunkAPI.dispatch(getShippingAddressById(res?.data?.ID))
          if (getShippingAddressById_res?.payload?.ID) {
            await thunkAPI.dispatch(handleSelectShippingAddress(getShippingAddressById_res?.payload))
          }
        } else {
          await thunkAPI.dispatch(joinShippingAndOrder({ ID: res?.data?.ID, order_id: cart.createCart_data?.order_id }))
          await thunkAPI.dispatch(getCart())
          await thunkAPI.dispatch(cartSlice.actions.setShippingAddress({ key: 'getShippingAddressById_data', data: data }))
        }
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const editShippingAddress = createAsyncThunk('cartSlice/editShippingAddress', async (data, thunkAPI) => {
  let { shipping_id, ...field } = data
  return await serviceController(`${routes.editShippingAddress}${shipping_id}`, field)
    .then(async res => {
      if (res?.data) {
        await thunkAPI.dispatch(getShippingAddress())
        let getShippingAddressById_res = await thunkAPI.dispatch(getShippingAddressById(shipping_id))
        if (getShippingAddressById_res?.payload?.ID) {
          await thunkAPI.dispatch(handleSelectShippingAddress(getShippingAddressById_res?.payload))
        }
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const removeShippingAddress = createAsyncThunk('cartSlice/removeShippingAddress', async (id, thunkAPI) => {
  return await serviceController(`${routes.removeShippingAddress}${id}`)
    .then(async res => {
      if (res?.data) {
        await thunkAPI.dispatch(getShippingAddress())
        await thunkAPI.dispatch(getCart())
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getDeliveryFee = createAsyncThunk('cartSlice/getDeliveryFee', async (township_id) => {
  return await serviceController(`${routes.getDeliveryFee}?township_id=${township_id}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getShippingLocations = createAsyncThunk('cartSlice/getShippingLocations', async (data) => {
  let { country_code, ...field } = data
  return await serviceController(`${routes.getShippingLocations}${country_code}${routeFilter(field)}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getAllLocation = createAsyncThunk('cartSlice/getAllLocation', async (data) => {
  let { country_code = 'MM', langCode, ...field } = data
  return await serviceController(`${routes.getAllLocation}${country_code}?lang=${langCode}`)
    .then(res => {
      if (res?.data) {
        let final_data = {
          data: res?.data
        }
        return final_data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const joinShippingAndOrder = createAsyncThunk('cartSlice/joinShippingAndOrder', async (data) => {
  return await serviceController(routes.joinShippingAndOrder, data)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getPaymentList = createAsyncThunk('cartSlice/getPaymentList', async () => {
  return await serviceController(routes.getPaymentList)
    .then(async res => {
      if (res?.data) {
        let tmp = checkChange('id', { data: res.data }, res?.data?.[0]?.id, 'single')
        let final_res = {
          data: tmp
        }
        if (tmp?.length > 0) {
          let check_tmp = tmp.filter(x => x.check)?.[0]?.id
          final_res.selected_payment_id = check_tmp
        }
        return final_res
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const payNow = createAsyncThunk('cartSlice/payNow', async (data, thunkAPI) => {
  return await serviceController(routes.payNow, data)
    .then(async res => {
      if (res?.data) {
        await thunkAPI.dispatch(getOrder({ limit: 10, offset: 1 }))
        if (res?.data?.status === 'success') {
          await thunkAPI.dispatch(cartSlice.actions.resetCart())
        }
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getOrder = createAsyncThunk('cartSlice/getOrder', async (data, thunkAPI) => {
  let { limit = 10, offset = 1, isStateClean = true, ...field } = data
  let order_data = thunkAPI.getState().cart.getOrder_data
  !isStateClean && thunkAPI.dispatch(cartSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: true, order_offset: offset }))

  return await serviceController(`${routes.getOrder}${offset}/${limit}`)
    .then(res => {
      if (res?.data) {
        if (order_data && !isStateClean) {
          let obj = {
            ...order_data,
            orders: [...order_data.orders, ...res.data.orders]
          }
          !isStateClean && thunkAPI.dispatch(cartSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: false }))
          return obj
        } else {
          isStateClean ? thunkAPI.dispatch(cartSlice.actions.handleBottomReach({ order_offset: 1 }))
            :
            thunkAPI.dispatch(cartSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: false }))
          return res.data
        }
      } else {
        !isStateClean && thunkAPI.dispatch(cartSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: false }))
        return res?.data
      }
    })
    .catch(error => error.message)
})

const getOrderById = createAsyncThunk('cartSlice/getOrderById', async (id) => {
  return await serviceController(`${routes.getOrderById}${id}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => error.message)
})

const handleSelectShippingAddress = createAsyncThunk('cartSlice/selectShippingAddress', async (data, thunkAPI) => {
  let { ID, ...field } = data
  let { getShippingAddress_data, createCart_data } = thunkAPI.getState().cart
  let tmp = checkChange('ID', getShippingAddress_data, ID, 'single')
  if (tmp?.length > 0) {
    let selected_item = tmp.filter(x => x.check)?.[0]
    let final_res = {
      selected_item,
      data: tmp
    }
    await thunkAPI.dispatch(joinShippingAndOrder({ ID: selected_item?.ID, order_id: createCart_data?.order_id }))
    await thunkAPI.dispatch(getCart())
    await thunkAPI.dispatch(cartSlice.actions.setShippingAddress({ key: 'getShippingAddress_data', data: final_res }))
    return final_res
  }
})

const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    setCartList: (state, action) => {
      if (action?.payload?.data?.status === 'success') {
        state.getCart_data.order_lines[action.payload.c_index][action.payload.key] = action.payload.data
      } else {
        state.getCart_data.order_lines[action.payload.c_index][action.payload.key] = action.payload.data
        state.getCart_data.order_lines[action.payload.c_index].quantity = action.payload.data.available_qty
        state.getCart_data.cart_item_count = action.payload.data.available_qty
      }
    },
    setLocationList: (state, action) => {
      let { type, checkListItem, data } = action.payload
      let tmp = checkChange(type, checkListItem, data)
      let selected_item = tmp.filter(x => x.check)
      let arr = []
      selected_item?.map(x => arr.push(x.id))
      let obj = {
        data: [{
          ...state.getAllLocation_data.data[0],
          state_ids: tmp
        }],
        selected_ids: arr,
        selected_item: selected_item,
      }
      state.getAllLocation_data = obj
    },
    setQtyInCartList: (state, action) => {
      state.getCart_data.order_lines[action.payload.c_index].quantity = action.payload.qty
    },
    handleBottomReach: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setShippingAddress: (state, action) => {
      state[action.payload.key] = action.payload.data
    },
    resetCart: (state, action) => {
      state.createCart_data = null
      state.getCart_data = null
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction('cartSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return ({
          ...state,
          isLoading: true,
          [tmp[1] + '_isLoading']: true,
          error: null
        })
      })
      .addMatcher(isFulfilledAction('cartSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return ({
          ...state,
          [tmp[1] + '_data']: action.payload,
          isLoading: false,
          [tmp[1] + '_isLoading']: false,
          error: null
        })
      })
      .addMatcher(isRejectedAction('cartSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return ({
          ...state,
          isLoading: false,
          [tmp[1] + '_isLoading']: false,
          error: action.payload
        })
      })
  }
})

export default {
  cartSlice: cartSlice.reducer,
  setCartList: cartSlice.actions.setCartList,
  handleBottomReach: cartSlice.actions.handleBottomReach,
  setQtyInCartList: cartSlice.actions.setQtyInCartList,
  setLocationList: cartSlice.actions.setLocationList,
  setShippingAddress: cartSlice.actions.setShippingAddress,
  resetCart: cartSlice.actions.resetCart,
  handleSelectShippingAddress,
  getCart,
  createCart,
  updateQtyInCart,
  updateCustomer,
  deleteCart,
  deleteAllInCart,
  getShippingAddress,
  getShippingAddressById,
  createShippingAddress,
  editShippingAddress,
  removeShippingAddress,
  getDeliveryFee,
  getShippingLocations,
  getAllLocation,
  joinShippingAndOrder,
  getPaymentList,
  payNow,
  getOrder,
  getOrderById
}