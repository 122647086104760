import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import serviceController, { routes, routeFilter } from 'controller'
import { checkChange, calcuPromo, wishlistHandleChange, UnitArray, handleNestedChange } from 'utils'
import { isPendingAction, isFulfilledAction, isRejectedAction } from '../typehandle.action'

const initialState = {
  isLoading: false,
  error: null,
  isBottomReach: false,
  product_offset: 1,
  product_limit: 10,
  sortBy: '',
}

// const getProductSearch = createAsyncThunk(
//   "productSlice/getProductSearch",
//   async (dispatch, getState) => {
//     return http({
//       method: 'get',
//       url: `product`,
//       ...dispatch
//     }).then(res => res)
//   }
// )

const sentProductRating = createAsyncThunk('productSlice/sentProductRating', async (data) => {
  console.log(data, 'data...............///')
  let { id, ...fields } = data
  return await serviceController(`${routes.sentProductRating}${id}/ratingreview`, fields)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getProduct = createAsyncThunk('productSlice/getProduct', async (data, thunkAPI) => {
  let { isStateClean = true, ...field } = data
  let product_data = thunkAPI.getState().product.getProduct_data
  !isStateClean && thunkAPI.dispatch(productSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: true, product_offset: field.page_number }))

  return await serviceController(`${routes.getProduct}${routeFilter(field)}`)
    .then(async res => {
      if (res?.data) {
        if (product_data && !isStateClean) {
          let obj = {
            ...product_data,
            product_list: [...product_data.product_list, ...res.data.product_list]
          }
          !isStateClean && thunkAPI.dispatch(productSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: false }))
          // calc promotion
          let final_res = await calcuPromo(obj, 'product_list')
          return final_res
        } else {
          isStateClean ? thunkAPI.dispatch(productSlice.actions.handleBottomReach({ product_offset: 1 }))
            :
            thunkAPI.dispatch(productSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: false }))
          // calc promotion
          let final_res = await calcuPromo(res.data, 'product_list')
          return final_res
        }
      } else {
        !isStateClean && thunkAPI.dispatch(productSlice.actions.handleBottomReach({ isLoading: false, isBottomReach: false }))
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getProductById = createAsyncThunk('productSlice/getProductById', async (data, thunkAPI) => {
  let getProfile_data = thunkAPI.getState()?.auth?.getProfile_data
  return await serviceController(`${routes.getProductById}${data.id}?lang=${data.langCode}`)
    .then(async res => {
      if (res?.data) {
        let obj = {
          quantity: 1,
          product_id: data.id,
          ...res.data
        }
        // change calc promotion json //
        if (res?.data?.promotion?.length > 0) {
          let promo_arr = await UnitArray(res?.data?.promotion, 'promotion_program_id')
          obj.promotion = promo_arr || []

          let final_res = await calcuPromo(obj, 'product_list', 'discount')
          obj = {
            ...final_res
          }
        }
        // change product gallery json //
        if (res?.data?.gallery?.length > 0) {
          let gall_arr = []
          res?.data?.gallery?.map(x => {
            gall_arr.push({ image: x })
          })
          obj.gallery = gall_arr
        }
        // change attributes json //
        if (res?.data?.attributes?.length > 0) {
          obj.check_stock = { desc: 'select_variant_each_attributes' }
          if (getProfile_data) {
            obj.check_wishlist = { exist: false }
          }
          obj.attributes = {
            data: res?.data?.attributes
          }
        } else {
          let pos_data = {
            product_id: data.id,
            qty: 1,
            item: res.data
          }
          let check_stock_res = await thunkAPI.dispatch(checkStock(pos_data))
          obj.check_stock = check_stock_res.payload
          if (getProfile_data) {
            let check_wishlist_res = await thunkAPI.dispatch(getExistsWishlist(pos_data))
            let myBool = (check_wishlist_res?.payload?.exist?.toLowerCase() === 'true')
            let res_obj = {
              ...check_wishlist_res.payload,
              exist: myBool
            }
            obj.check_wishlist = res_obj
          }
        }
        return obj
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getProductCategory = createAsyncThunk('productSlice/getProductCategory', async (data, thunkAPI) => {
  return await serviceController(`${routes.getProductCategory}${routeFilter(data)}`)
    .then(res => {
      if (res?.data) {
        let tmp = handleNestedChange(res?.data, 'category_id', 'sub_category')
        let obj = {
          data: res?.data
        }
        return obj
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

type CheckStockProps = {
  item: object,
  type: string,
  product_id: number,
  qty: number,
  variant_id: any
}
const checkStock = createAsyncThunk('productSlice/checkStock', async (data: CheckStockProps, thunkAPI) => {
  let { item, type = 'productbyid', ...field } = data
  if (item?.attributes?.data?.length > 0) {
    if (item?.attributes?.data?.length === field?.variant_id?.toString()?.split(',')?.length) {
      let res = await handleCheckStockApi(data, thunkAPI)
      return res
    } else {
      if (type === 'productbyid') {
        await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_stock', data: { desc: 'select_variant_each_attributes' } }))
      } else if (type === 'wishlist') {
        await thunkAPI.dispatch(productSlice.actions.setWishlistList({ key: 'check_stock', data: { desc: 'select_variant_each_attributes' } }))
      } else if (type === 'cartlist') {

      } else if (type === 'addtocart') {
        await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_stock', data: { desc: 'select_variant_each_attributes' } }))
        return (
          { desc: 'select_variant_each_attributes' }
        )
      } else {

      }
    }
  } else {
    let res = await handleCheckStockApi(data, thunkAPI)
    return res
  }
})

type HandleCheckStockApiProps = {

}
const handleCheckStockApi = async (data: HandleCheckStockApiProps, thunkAPI) => {
  let { item, type = 'productbyid', w_index, ...field } = data
  if (item?.variant_id && type === 'wishlist') {
    field.variant_id = item.variant_id
  }
  return await serviceController(`${routes.checkStock}${routeFilter(field)}`)
    .then(async res => {
      if (res?.data?.in_stock) {
        await thunkAPI.dispatch(productSlice.actions.setHandleQty({ qty: field?.qty, type, w_index, key: 'check_stock', data: res?.data }))
        return res?.data
      } else {
        if (type === 'productbyid') {
          await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_stock', data: res.data }))
          await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'quantity', data: res?.data?.available_qty > 0 ? res?.data?.available_qty : 1 }))
        } else if (type === 'wishlist') {
          await thunkAPI.dispatch(productSlice.actions.setWishlistList({ key: 'check_stock', w_index, data: res.data }))
          await thunkAPI.dispatch(productSlice.actions.setWishlistList({ key: 'quantity', w_index, data: res?.data?.available_qty > 0 ? res?.data?.available_qty : 1 }))
        } else if (type === 'cartlist') {

        } else if (type === 'addtocart') {
          await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_stock', data: res.data }))
          await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'quantity', data: res?.data?.available_qty > 0 ? res?.data?.available_qty : 1 }))
        }

        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
}

const getRelatedProduct = createAsyncThunk('productSlice/getRelatedProduct', async (category_id, count = 10) => {
  return await serviceController(`${routes.getRelatedProduct}${category_id}/related/${count}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getExistsWishlist = createAsyncThunk('productSlice/getExistsWishlist', async (data, thunkAPI) => {
  let { item, qty, isExistCheck = true, ...field } = data
  if (item?.attributes?.data?.length > 0) {
    if (item?.attributes?.data?.length === field?.variant_id?.toString()?.split(',')?.length) {
      return await serviceController(`${routes.getExistsWishlist}${routeFilter(field)}`)
        .then(res => {
          if (res?.data) {
            if (isExistCheck) {
              let myBool = (res?.data?.exist?.toLowerCase() === 'true')
              thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_wishlist', data: { ...res.data, exist: myBool } }))
              return res?.data
            } else {
              let myBool = (res?.data?.exist?.toLowerCase() === 'true')
              if (myBool) {
                thunkAPI.dispatch(removeWishlist({ wishlist_id: res?.data?.w_id }))
              } else {
                let req_obj = {
                  ...field,
                  variant_id: data?.variant_id?.toString()
                }
                thunkAPI.dispatch(createWishlist(req_obj))
              }
              return res?.data
            }
          } else {
            return res?.data
          }
        })
        .catch(error => console.log('error.message', error.message))
    } else {
      await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_wishlist', data: { exist: false, desc: 'select_variant_each_attributes' } }))
    }
  } else {
    return await serviceController(`${routes.getExistsWishlist}${routeFilter(field)}`)
      .then(res => {
        if (res?.data) {
          if (isExistCheck) {
            return res?.data
          } else {
            let myBool = (res?.data?.exist?.toLowerCase() === 'true')
            if (myBool) {
              thunkAPI.dispatch(removeWishlist({ wishlist_id: res?.data?.w_id }))
            } else {
              thunkAPI.dispatch(createWishlist(field))
            }
            return res?.data
          }
        } else {
          return res?.data
        }
      })
      .catch(error => console.log('error.message', error.message))
  }
})

const createWishlist = createAsyncThunk('productSlice/createWishlist', async (data, thunkAPI) => {
  let { langCode, ...field } = data
  return await serviceController(routes.createWishlist, field)
    .then(res => {
      if (res?.data) {
        thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_wishlist', data: { exist: true } }))
        thunkAPI.dispatch(getWishlist(langCode))
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const removeWishlist = createAsyncThunk('productSlice/removeWishlist', async (data, thunkAPI) => {
  let { langCode, ...field } = data
  return await serviceController(routes.removeWishlist, { data: field })
    .then(res => {
      if (res?.data) {
        thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'check_wishlist', data: { exist: false } }))
        thunkAPI.dispatch(getWishlist(langCode))
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getWishlist = createAsyncThunk('productSlice/getWishlist', async (langCode = 'en') => {
  return await serviceController(`${routes.getWishlist}?lang=${langCode}`)
    .then(res => {
      if (res?.data) {
        if (res?.data?.length > 0) {
          let tmp = res.data.map(x => ({
            ...x,
            quantity: 1,
            check_stock: {
              in_stock: x.status === 'in_stock'
            }
          }))
          return tmp
        }
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getPromotion = createAsyncThunk('productSlice/getPromotion', async () => {
  return await serviceController(routes.getPromotion)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getPromotionById = createAsyncThunk('productSlice/getPromotionById', async (data) => {
  let { id, ...field } = data
  return await serviceController(`${routes.getPromotionById}${id}/products${routeFilter(field)}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getProductGroup = createAsyncThunk('productSlice/getProductGroup', async (code, props) => {
  let product_gpdata = props.getState()?.product?.getProductGroup_data
  return await serviceController(`${routes.getProductGroup}${code}/products`)
    .then(async res => {
      if (res?.data) {
        let tmp = await calcuPromo(res.data, 'products', 'discount')
        let obj = product_gpdata ? {
          ...product_gpdata,
          [code]: tmp
        } : { [code]: tmp }
        return obj
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getFlashSaleList = createAsyncThunk('productSlice/getFlashSaleList', async (langCode = 'en') => {
  return await serviceController(`${routes.getFlashSaleList}?lang=${langCode}`)
    .then(res => {
      if (res?.data) {
        return res?.data
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getFlashSaleDetail = createAsyncThunk('productSlice/getFlashSaleDetail', async (data) => {
  let { id, ...field } = data
  return await serviceController(`${routes.getFlashSaleDetail}${id}/products${routeFilter(field)}`)
    .then(async res => {
      if (res?.data) {
        let tmp = await calcuPromo(res?.data, 'product_list', 'flash_sale')
        return tmp
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const getBrandList = createAsyncThunk('productSlice/getBrandList', async data => {
  return await serviceController(`${routes.getBrandList}${routeFilter(data)}`)
    .then(res => {
      if (res?.data) {
        let tmp = {
          data: res.data
        }
        return tmp
      } else {
        return res?.data
      }
    })
    .catch(error => console.log('error.message', error.message))
})

const handleVariant = createAsyncThunk('productSlice/handleVariant', async (data, thunkAPI) => {
  let getProductById_data = thunkAPI.getState()?.product?.getProductById_data
  let calc_attribute = wishlistHandleChange(data)
  let calc_price = getProductById_data.price + (calc_attribute?.extra_price_for_variant || 0)

  await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'attributes', data: calc_attribute }))
  await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'extra_price_for_variant', data: calc_price }))

  // calc change promotion //
  if (getProductById_data?.promotion?.length > 0) {
    let final_res = await calcuPromo(getProductById_data, '', 'discount', calc_price)
    await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'promo_price', data: final_res?.promo_price }))
    await thunkAPI.dispatch(productSlice.actions.setProductDetail({ key: 'cal_discount_percent', data: final_res?.cal_discount_percent }))
  }

  let check_pos_data = {
    item: getProductById_data,
    product_id: getProductById_data?.product_id,
    variant_id: calc_attribute?.selected_variant_ids?.length > 0 ? calc_attribute?.selected_variant_ids?.toString() : null,
    qty: getProductById_data?.quantity
  }
  await thunkAPI.dispatch(checkStock(check_pos_data))
  await thunkAPI.dispatch(getExistsWishlist(check_pos_data))
})

const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  reducers: {
    clearSearchAction: (state, action) => {
      state.getProductsSearch_data[action.payload.key] = {}
    },
    setProductDetail: (state, action) => {
      state.getProductById_data[action.payload.key] = action.payload.data
    },
    setWishlistList: (state, action) => {
      state.getWishlist_data[action.payload.w_index][action.payload.key] = action.payload.data
    },
    setHandleQty: (state, action) => {
      if (action?.payload?.type === 'productbyid') {
        state.getProductById_data.quantity = action.payload.qty
        state.getProductById_data[action.payload.key] = action.payload.data
      } else if (action?.payload?.type === 'wishlist') {
        state.getWishlist_data[action.payload.w_index].quantity = action.payload.qty
        state.getWishlist_data[action.payload.w_index][action.payload.key] = action.payload.data
      } else if (action?.payload?.type === 'cartlist') {

      } else {

      }
    },
    setProductCategory: (state, action) => {
      let { key } = action.payload
      let tmp_string_arr = key.split('-')
      let last_id = tmp_string_arr?.[tmp_string_arr?.length - 1]

      let final_obj = {
        ...state.getProductCategory_data,
        selected_ids: Number(last_id) === Number(state.getProductCategory_data.selected_ids) ? null : Number(last_id),
        key: Number(last_id) === Number(state.getProductCategory_data.selected_ids) ? null : key
      }
      state.getProductCategory_data = final_obj
    },
    setBrandList: (state, action) => {
      let { type, checkListItem, data } = action.payload
      let tmp = checkChange(type, checkListItem, data)
      let selected_item = tmp.filter(x => x.check)
      let arr = []
      selected_item?.map(x => arr.push(x.ID))
      let obj = {
        data: tmp,
        selected_ids: arr,
        selected_item: selected_item,
      }
      state.getBrandList_data = obj
    },
    resetBrandChange: (state, action) => {
      let array = action.payload
      if (array?.length > 0) {
        let res = array.map(x => ({ ...x, check: false }))
        if (res?.length > 0) {
          let obj = {
            data: res,
            selected_ids: [],
            selected_item: [],
          }
          state.getBrandList_data = obj
        }
      }
    },
    handleBottomReach: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setSortBy: (state, action) => ({
      ...state,
      ...action.payload
    }),
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction('productSlice/'), state => ({
        ...state,
        isLoading: true,
        error: null
      }))
      .addMatcher(isFulfilledAction('productSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return ({
          ...state,
          [tmp[1] + '_data']: action.payload,
          isLoading: false,
          error: null
        })
      })
      .addMatcher(isRejectedAction('productSlice/'), (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload
      }))
  }
})

export default {
  productSlice: productSlice.reducer,
  setProductDetail: productSlice.actions.setProductDetail,
  setWishlistList: productSlice.actions.setWishlistList,
  setProductCategory: productSlice.actions.setProductCategory,
  setBrandList: productSlice.actions.setBrandList,
  resetBrandChange: productSlice.actions.resetBrandChange,
  handleBottomReach: productSlice.actions.handleBottomReach,
  setSortBy: productSlice.actions.setSortBy,
  handleVariant,
  getProduct,
  getProductById,
  getProductCategory,
  checkStock,
  getRelatedProduct,
  createWishlist,
  getWishlist,
  getExistsWishlist,
  removeWishlist,
  getPromotion,
  getPromotionById,
  getProductGroup,
  getFlashSaleList,
  getFlashSaleDetail,
  getBrandList,
  sentProductRating
}