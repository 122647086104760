import React from "react";
import styled from "styled-components";
import { Text } from "theme";

const Ellipsis = styled(Text)`
  height: ${(props) => props.weight || 40}px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  display: inline-block;
`;

export const TextEllipsis = (props) => {
  return (
    <Ellipsis className={props.className} {...props}>
      {props.children}
    </Ellipsis>
  );
};
