import React, { useState } from 'react'
import ReactImageMagnify from 'react-image-magnify'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

import { StyledRSSlider } from 'theme'

type RSSliderProps = {
  rimProps: object,
  rsProps: object,
  type: string,
  data: any[],
  innerRef: any,
  leftArrowSvg: any,
  rightArrowSvg: any,
  renderLeftArrow: any,
  renderRightArrow: any,
  renderContent: any
}
export const RSSliderCom: React.FC<RSSliderProps> = ({ rimProps, rsProps, type, data, innerRef, leftArrowSvg, rightArrowSvg, renderLeftArrow, renderRightArrow, renderContent, ...props }) => {
  const [hoverSrc, setHoverSrc] = useState(false)
  let img_media = ['500w', '779w', '1020w', '1200w', '1426w']

  let dataSource = data.map(x => (
    {
      ...x,
      srcSet: img_media.map(y => `${x.image || require('assets/images/demo/home_cart_1.png')} ${y}`).join(', '),
      small: x.image || require('assets/images/demo/home_cart_1.png'),
      large: x.image || require('assets/images/demo/home_cart_1.png')
    }
  ))

  const NextArrow = props => {
    const { className, onClick } = props
    return (
      renderRightArrow
        ?
        renderRightArrow(props)
        :
        <div
          className={`next ${className.includes('slick-disabled') ? 'disabled' : undefined}`}
          onClick={onClick}
        >
          {rightArrowSvg || <AiOutlineRight />}
        </div>
    )
  }

  const PrevArrow = props => {
    const { className, onClick } = props
    return (
      renderLeftArrow
        ?
        renderLeftArrow(props)
        :
        <div
          className={`prev ${className.includes('slick-disabled') ? 'disabled' : undefined}`}
          onClick={onClick}
        >
          {leftArrowSvg || <AiOutlineLeft />}
        </div>
    )
  }

  let RSProps = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    pauseOnHover: true,
    ...rsProps
  }

  let RIMProps = src => ({
    smallImage: {
      alt: 'Wristwatch by Versace',
      isFluidWidth: true,
      src: src.small,
      srcSet: src.srcSet,
      sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
    },
    largeImage: {
      src: src.large,
      width: 1426,
      height: 2000
    },
    lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
    ...rimProps
  })

  return (
    <div style={{ position: 'relative' }}>
      <StyledRSSlider
        className='cu-slide'
        {...RSProps}
        {...props}
        ref={innerRef}
      >
        {props?.children ?
          props?.children
          :
          dataSource?.length > 0 && dataSource.map((src, index) => (
            <div style={{ position: 'relative' }} onMouseMove={e => setHoverSrc(type === 'zoom' ? src : false)} key={index}>
              <img style={{ width: '100%' }} src={src.large} />
              {
                renderContent && renderContent(src, index)
              }
            </div>
          ))
        }
      </StyledRSSlider>
      {
        hoverSrc &&
        <div onMouseLeave={_ => setHoverSrc(false)} style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
          <ReactImageMagnify
            {...RIMProps(hoverSrc)}
            {...props}
          />
        </div>
      }
    </div>
  )
}

RSSliderCom.defaultProps = {
  data: [],
  type: 'simple'
}