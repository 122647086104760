import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { StyledInputGroup, Input, Label } from "../../../theme";
import { ButtonCom } from "../button";
import { moneyFormat } from "../../../utils";
import { Text } from "../typo";
import {
  CategorySideBarContainer,
  SideBarUl,
  SideBarTitle,
  SideBarList,
  SideBarDrp,
  SideBarFilter,
  FilterTitle,
} from "theme";
import { HiOutlineChevronRight } from "react-icons/hi";
import { RiFilter2Fill } from "react-icons/ri";
import { useHandleOther } from "../../../hook";
const queryString = require("query-string");

export const CategorySideBar = (props) => {
  const history = useNavigate();
  const { translate } = useHandleOther();
  const {
    data,
    product_list,
    handleCheck,
    handleFilter,
    translateData,
    searchpage,
    pName,
    isLoading,
    division_code,
  } = props;
  const [state, setState] = useState({
    min: "",
    max: "",
    selectedToggle: false,
    selectedIndex: null,
  });
  const [productValue, setProductValue] = useState(pName || "");
  // console.log("state", state);
  setTimeout(() => {
    let array = document.querySelectorAll(".check-item-con");
    if (array?.length > 0) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        let tmp = element.querySelectorAll(".custom-check");
        if (tmp.length > 10) {
          let height = 0;
          for (let i = 0; i <= 10; i++) {
            const ele = tmp[i];
            height += ele?.offsetHeight;
          }
          element.style.height = height + 25 + "px";
        }
      }
    }
  }, 300);
  // console.log("data", data?.brandData.length);
  const searchObj =
    history?.location?.search !== "" &&
    queryString.parse(history?.location?.search);

  const hadleProductSearchKey = (e) => {
    setProductValue(e.target.value);
  };
  // console.log("data?.countryData", data?.countryData);
  const handleTextChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };
  return (
    <CategorySideBarContainer>
      {!searchpage && data.categoryData && (
        <SideBarUl>
          <SideBarTitle>
            {translate("shop_by_category", "Shop by category")}
          </SideBarTitle>
          {/* <FilterTitle>{translate(shop_by_category, '')}</FilterTitle> */}
          {data.categoryData?.data?.length > 0 ? (
            data.categoryData?.data?.map((x, i) => (
              <SideBarDrp
                key={i}
                className={
                  +state.selectedIndex?.split("-")[0] === i ? "active" : ""
                }
                onClick={() => {
                  if (x?.sub_category?.length === 0) {
                    setState({
                      ...state,
                      selectedToggle: !state.selectedToggle,
                      selectedIndex:
                        state.selectedToggle &&
                          +state.selectedIndex?.split("-")[0] - i === 0
                          ? null
                          : `${i}`,
                    });
                    handleFilter(
                      "category_id",
                      state.selectedToggle &&
                        +state.selectedIndex?.split("-")[0] - i === 0
                        ? null
                        : x.category_id,
                      `${i}-0`
                    );
                  }
                }}
              >
                {/* x.sub_category[0].category_name */}
                {x.category_name && <span>{x.category_name}</span>}
                {x.name && <span>{x.name}</span>}
                {x?.sub_category?.length > 0 && <HiOutlineChevronRight />}

                {x?.sub_category?.length > 0 && (
                  <div className="sub-drp">
                    <ul>
                      {x?.sub_category?.map((y, index) => (
                        <SideBarList
                          key={index}
                          className={
                            state?.selectedIndex === `${i}-${index}`
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            setState({
                              ...state,
                              selectedToggle: !state.selectedToggle,
                              selectedIndex:
                                state.selectedToggle &&
                                  +state.selectedIndex?.split("-")[1] - index ===
                                  0
                                  ? null
                                  : `${i}-${index}`,
                            });
                            handleFilter(
                              "category_id",
                              state.selectedToggle &&
                                +state.selectedIndex?.split("-")[1] - index ===
                                0
                                ? null
                                : y.category_id,
                              `${i}-${index}`
                            );
                          }}
                        >
                          {y.category_name}
                        </SideBarList>
                      ))}
                    </ul>
                  </div>
                )}
              </SideBarDrp>
            ))
          ) : (
            <Text color="secondary" style={{ marginLeft: "10px" }}>
              {translate("no_data", "No data")}
            </Text>
          )}
        </SideBarUl>
      )}

      <SideBarFilter productName>
        <FilterTitle>
          {translate("by_product_name", "By product name")}
        </FilterTitle>
        <div className="flex">
          {/* <StyledInputGroup>
            <Input type="number" min="1" placeholder={translate(min_key, '')} value={state?.min} onChange={e => handleTextChange('min', e.target.value)} />
            <Input
              placeholder={translate("search_product", "Search product")}
              value={productValue}
              onChange={hadleProductSearchKey}
              onKeyPress={(e) =>
                e.key === "Enter" && handleFilter("productName", productValue)
              }
            />
          </StyledInputGroup> */}
          {/* <Button
            className="btn-pname"
            primary
            onClick={() => handleFilter("productName", productValue)}
          >
            {translate("search_key", "Search key")}
          </Button> */}
        </div>
      </SideBarFilter>

      {data?.brandData?.length > 0 && (
        <SideBarFilter>
          <FilterTitle>{translate("brands", "Brands")}</FilterTitle>
          <div className="check-item-con">
            {/* {data?.brandData.map((x, i) => (
              <StyledInputGroup className="custom-check" key={i}>
                <Input
                  type="checkbox"
                  name="check"
                  id={`brand-check-${i}`}
                  checked={
                    searchObj?.ID?.split(",").includes(x?.ID.toString()) ||
                    x?.check ||
                    ""
                  }
                  onChange={() =>
                    handleCheck("brand_id", data?.brandData, x.ID)
                  }
                  checkbox
                />
                <Label htmlFor={`brand-check-${i}`}>{x.name}</Label>
              </StyledInputGroup>
            ))} */}
          </div>
        </SideBarFilter>
      )}

      {data?.genericName_data?.length > 0 &&
        (division_code === "pharma" || division_code === "nutrition") && (
          <SideBarFilter>
            <FilterTitle>
              {translate("by_generic_name", "By generic name")}
            </FilterTitle>
            <div className="check-item-con">
              {/* {data?.genericName_data.map((x, i) => (
                <StyledInputGroup className="custom-check" key={i}>
                  <Input
                    type="checkbox"
                    name="check"
                    id={`generic-check-${i}`}
                    checked={
                      searchObj?.generic_id
                        ?.split(",")
                        .includes(x?.id.toString()) ||
                      x?.check ||
                      ""
                    }
                    onChange={() =>
                      handleCheck("generic_id", data?.genericName_data, x.id)
                    }
                    checkbox
                  />
                  <Label htmlFor={`generic-check-${i}`}>{x.name}</Label>
                </StyledInputGroup>
              ))} */}
            </div>
          </SideBarFilter>
        )}

      {data?.principle_data?.length > 0 && (
        <SideBarFilter>
          <FilterTitle>{translate("by_principle_manufacture", "")}</FilterTitle>
          <div className="check-item-con">
            {/* {data?.principle_data.map((x, i) => (
              <StyledInputGroup className="custom-check" key={i}>
                <Input
                  type="checkbox"
                  name="check"
                  id={`principle-check-${i}`}
                  checked={
                    searchObj?.principle_id
                      ?.split(",")
                      .includes(x?.id.toString()) ||
                    x?.check ||
                    ""
                  }
                  onChange={() =>
                    handleCheck("principle_id", data?.principle_data, x.id)
                  }
                  checkbox
                />
                <Label htmlFor={`principle-check-${i}`}>{x.name}</Label>
              </StyledInputGroup>
            ))} */}
          </div>
        </SideBarFilter>
      )}

      <SideBarFilter price>
        <FilterTitle>{translate("price_key", "")}</FilterTitle>
        {/* <div className="flex">
          <StyledInputGroup>
            <Input
              type="number"
              min="1"
              placeholder={translate("min_key", "")}
              value={state?.min}
              onChange={(e) => handleTextChange("min", e.target.value)}
            />
          </StyledInputGroup>
          <StyledInputGroup>
            <Input
              type="number"
              min="1"
              placeholder={translate("max_key", "")}
              value={state?.max}
              onChange={(e) => handleTextChange("max", e.target.value)}
            />
          </StyledInputGroup>
          <ButtonCom
            primary
            onClick={() =>
              handleFilter("price", { min: state.min, max: state.max })
            }
          >
            <RiFilter2Fill />
          </ButtonCom>
        </div> */}
      </SideBarFilter>

      {data?.countryData?.length > 0 && (
        <SideBarFilter>
          <FilterTitle>{translate("country_origin", "")}</FilterTitle>
          {/* {data?.countryData?.map((x, i) => (
            <StyledInputGroup className="custom-check" key={i}>
              <Input
                type="checkbox"
                name="check"
                id={`country-check-${i}`}
                checked={
                  searchObj?.country_id
                    ?.split(",")
                    .includes(x?.country_id.toString()) ||
                  x?.check ||
                  ""
                }
                onChange={() =>
                  handleCheck("country_id", data?.countryData, x.country_id)
                }
                checkbox
              />
              <Label htmlFor={`country-check-${i}`}>{x.name}</Label>
            </StyledInputGroup>
          ))} */}
        </SideBarFilter>
      )}
    </CategorySideBarContainer>
  );
};
