import React from 'react'

import { StyledVariantCon, StyledVariantItemCon } from 'theme'
import { CardCom, TextCom } from '../../common'
import { useHandleVariant } from 'hook'

type AttributeButtonModProps = {

}

export const AttributeButtonMod: React.FC<AttributeButtonModProps> = props => {
  const [variants, handleVariant] = useHandleVariant(props)

  return (
    variants?.length > 0 &&
    variants?.map((x, i) => (
      <StyledVariantCon key={i}>
        <TextCom size='sm' weight='xl' style={{marginBottom: 5}}>{x?.name}</TextCom>
        <StyledVariantItemCon>
          {
            x?.vals?.length > 0 &&
            x?.vals?.map((z, index) => (
              <CardCom key={index} className='card_wrap' style={{ backgroundColor: z.check ? '#333333' : 'transparent' }} onClick={() => handleVariant(x?.id, z?.id)}>
                <TextCom color={z.check ? 'light' : 'text'} size='xs'>{z.name}</TextCom>
              </CardCom>
            ))
          }
        </StyledVariantItemCon>
      </StyledVariantCon>
    ))
  )
}