import React, { useState } from 'react'

import { ButtonCom, ModalCom, TextCom } from '../../common'
import { useHandleCart, useHandleOther } from 'hook'

type AddToCartButtonModProps = {}

export const AddToCartButtonMod: React.FC<AddToCartButtonModProps> = ({ navigate, params, w_item, type, ...props }) => {
  const { handleAddToCart } = useHandleCart()
  const { translate } = useHandleOther()
  const [state, setstate] = useState({
    modalVisible: false
  })

  const onHandleAddToCart = async () => {
    let res = await handleAddToCart(params?.id || w_item?.product_template_id, w_item?.quantity)
    if (res?.payload?.status === 'success') {
      if (type === 'buynow') {
        handleRoute('/order/checkout')
      } else {
        handleModal(true, translate('product_succcessfully_cart', 'Product successfully cart'))
      }
    } else {
      // showToast({ type: 'error', placement: 'bottom', title: 'Something wrong add to cart process!' })
    }
  }

  const handleModal = (modalVisible: boolean, desc: string) => {
    setstate({
      ...state,
      modalVisible,
      desc
    })
  }

  const handleRoute = (route, params) => {
    navigate(route, { ...params })
    handleModal(false)
  }

  return (
    <>
      <ButtonCom
        color='light'
        btnHeight={39}
        onClick={onHandleAddToCart}
        {...props}
      />
      <ModalCom
        isModal={state.modalVisible}
        handleIsModal={() => handleModal(false)}
      >
        <div>
          <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 26, paddingLeft: 20, paddingRight: 20 }}>
            <TextCom color='text' style={{ textAlign: 'center', marginBottom: 20 }}>{state?.desc}</TextCom>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonCom
                color='light'
                text={translate('go-to-cart', 'Go to cart')}
                onClick={() => handleRoute('/order/cart')}
              />
            </div>
          </div>
        </div>
      </ModalCom>
    </>
  )
}