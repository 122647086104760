import styled from "styled-components";
import { rgba } from "polished";

import { colors, generalGap, media } from "../../attribute";
export const StyledModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: ${rgba(colors.gray, 0.65)};
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.15s ease;
  
  &.show {
    visibility: visible;
    opacity: 1;
    > {
      &:first-child {
        transform: translateY(0);
      }
    }
  }
  &.fade {
    &.show {
      > {
        &:first-child {
          /* transform: translateY(30px); */
          /* @media screen and (min-width: ${media.md}px) {
            transform: translateY(50px);
          } */
        }
      }
    }
  }
  .success-btn {
    button {
      margin-right: 12px;
    }
  }
`;

export const StyledModalDialog = styled.div`
  padding: 15px;
  position: relative;
  z-index: 1;
  max-height: 75vh;
  max-width: ${props => props?.modalWidth || 850}px;
  transition: all 0.3s ease;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  };
`;

export const StyledModalContent = styled.div`
  background-color: ${colors.light};
  outline: 0;
  border: 0;
  border-radius: ${props => props?.theme?.generalGap?.xs || 0}px;
  /* border-radius: ${generalGap.xl}px; */
  box-shadow: 0 5px 11px 0 ${rgba(colors.gray, 0.18)},
    0 4px 15px 0 ${rgba(colors.gray, 0.15)};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 30px;
  p{text-align: center;};

  &.toast-content{
    border-radius: 15px;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledCloseOutside = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledModalClose = styled.div`
  background-color: ${rgba(colors.dark, 1)};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  svg {
    color: ${colors.light};
  }
`;

export const StyledExpandedIcon = styled.div`
  margin-bottom: 20px;
`