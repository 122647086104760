import { lazy } from 'react'

const productlist = lazy(() => import('./productlist'))
const productdetail = lazy(() => import('./productdetial'))
const productsearchlist = lazy(() => import('./productsearchlist'))
const promotion = lazy(() => import('./promotion'))

const Product = {
  productlist,
  productdetail,
  productsearchlist,
  promotion
}

export default Product