import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { ToastCom } from 'components'
import { useTheme, useOther } from 'hook'
import AppLayout from './layout'

type AppProps = {

}
const App: React.FC<AppProps> = () => {
  const { baseTheme } = useTheme()
  const { langStore, OtherAction, dispatch } = useOther()

  useEffect(() => {
    dispatch(OtherAction.getDictionary())
  }, [])

  let BaseTheme = {
    ...baseTheme,
    langStore
  }

  return (
    <ThemeProvider theme={BaseTheme}>
      <ToastCom />
      <BrowserRouter>
        <AppLayout />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App